import { IonText } from "@ionic/react"

const translations = {
    'created': 'Sırada',
    'getting-ready': 'Hazırlanır',
    'ready': 'Hazır',
    'on-way': 'Yolda',
    'at-address': 'Adresdə',
    // 'cancelled': 'Ləğv edildi',
    'delivered': 'Çatdırılıb'
}

export default function OrderState({order, ...rest}) {
    return <IonText {...rest}>
        {translations[order.state]}
    </IonText>
}