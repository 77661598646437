import { IonButton, IonButtons, IonCard, IonCardContent, IonCol, IonContent, IonFooter, IonHeader, IonIcon, IonModal, IonPage, IonRow, IonText, IonTitle, IonToolbar } from "@ionic/react";
import { trash } from "ionicons/icons";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { AppContext } from "../../App";
import useToast from "../../hooks/useToast";
import { deleteCart, getCart, deleteUser } from "../../services/getir";
import { price } from "../../utils/format";
import Alert from "../../components/Alert";
import CheckoutButton from "../../components/CheckoutButton";
import { GetirProduct } from "../../components/Getir";
import { Input } from "../../components";
import Header from "../../components/Header";
import { close } from "ionicons/icons";
// import './styles.css'
import { Logo } from "../../components";
import useToggleTab from "../../hooks/useToggleTab";

export default function DeleteUser() {
    const history = useHistory();
    const { logout } = useContext(AppContext);
    
    const submitDeleteUserHandler = async () => {
        const deletedUser = await deleteUser();
        console.log(deletedUser);
        logout();
        history.push("/auth");
    }

    return ( 
        <IonPage id="Cart">
            <Header title="Hesabı sil" />
            <IonContent>
                Hesabı silin
            </IonContent>
            <IonButton color="primary" onClick={() => submitDeleteUserHandler()}>
                Təsdiqlə
            </IonButton>
        </IonPage>
    )
}