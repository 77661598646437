import { IonButton, IonCard, IonCol, IonIcon, IonLabel, IonRow } from "@ionic/react";
import { cart } from "ionicons/icons";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { AppContext } from "../../App";
import { price } from "../../utils/format";
import './styles.css';

export default function CartButton({ amount, isDisabled, ...rest }) {
    const { cartTotal } = useContext(AppContext);
    const history = useHistory();

    return cartTotal === 0 && !amount
        ? <div />
        : <IonCard {...rest} id="CartButton" className="ion-no-margin" onClick={() => !isDisabled && history.push('/cart')}>
            <IonRow>
                <IonCol size={3} className="ion-text-start">
                    <div className="icon-container">
                        <IonIcon src={cart} color="primary"/>
                    </div>
                </IonCol>
                <IonCol size={9} className="price-col ion-text-center">
                    <span>{price(amount || cartTotal)}</span>
                </IonCol>
            </IonRow>
        </IonCard>

}