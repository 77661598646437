import { IonButton, IonCard, IonContent, IonFooter, IonIcon, IonItem, IonItemOption, IonItemOptions, IonItemSliding, IonLabel, IonList, IonPage, IonToolbar } from "@ionic/react";
import { checkmarkCircle, trash } from "ionicons/icons";
import { useContext } from "react";
import { useHistory } from "react-router";
import { AppContext } from "../../App";
import houseIcon from '../../assets/images/house.svg';
import { Alert, Header } from "../../components";
import useApi from "../../hooks/useApi";
import { removeAddress, updateAddress } from "../../services/getir";
import './styles.css';

export default function MyAddresses() {
    const history = useHistory();
    const [api] = useApi();
    const { setAddresses, addresses, defaultAddress, setLoading } = useContext(AppContext);

    const deleteAddress = async (_id) => {
        setLoading(true)
        try {
            await api(removeAddress, ({ addressId: _id }));
            setAddresses(prev => prev.filter(p => p._id !== _id))
        } catch (error) {

        }
        setLoading(false)
    }

    const setDefaultAddress = async (_id) => {
        setLoading(true);
        try {
            await api(updateAddress, { defaultAddressId: _id });
            setAddresses(prev => prev.map(p => {
                if (p.isDefault) p.isDefault = false;
                if (p._id === _id) p.isDefault = true;
                return p
            }))
        } catch (error) {

        }
        setLoading(false)
    }

    return <IonPage id="MyAddresses">
        <Header title="Ünvanlar" backButton />
        <IonContent className="ion-padding-vertical">
            {defaultAddress ? <IonCard>
                <IonItem>
                    <IonIcon slot="start" src={houseIcon} />
                    <IonLabel className="ion-text-wrap">
                        <strong>{defaultAddress.name}</strong>
                        <p>{defaultAddress.directions}</p>
                    </IonLabel>
                    <IonButton fill="clear" slot="end">
                        <IonIcon color="success" src={checkmarkCircle} />
                    </IonButton>
                </IonItem>


                {addresses.filter(a => !a.isDefault).map(a => <IonItemSliding key={a._id}>
                    <IonItem
                        onClick={() => setDefaultAddress(a._id)}
                        detail={false} button >
                        <IonIcon slot="start" src={houseIcon} />
                        <IonLabel className="ion-text-wrap">
                            <strong>{a.name}</strong>
                            <p>{a.directions}</p>
                        </IonLabel>
                    </IonItem>
                    <IonItemOptions side="end">
                        <IonItemOption onClick={() => deleteAddress(a._id)}>
                            <IonIcon src={trash} slot="icon-only" />
                        </IonItemOption>
                    </IonItemOptions>
                </IonItemSliding>)}
            </IonCard> : <IonCard>
                <Alert>
                    Heç bir ünvan tapılmadı. Zəhmət olmasa, yeni ünvan artırın.
                </Alert></IonCard>}
        </IonContent>
        <IonFooter className="ion-no-border">
            <IonToolbar className="ion-padding-bottom">
                <IonButton className="ion-margin" expand="block" onClick={() => history.push('/add-address')}>
                    Ünvan Artır
                </IonButton>
            </IonToolbar>
        </IonFooter>
    </IonPage>
}