const orderStates = [
    'created',
    'getting-ready',
    'ready',
    'on-way',
    'at-address',
    // 'cancelled',
    'delivered',
    'hot'
]

export default orderStates 