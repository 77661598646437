import * as api from '../utils/api'

const searchEntity = async (data) => api.post(`/search/${data.entity}`, data)

const login = async (data) => api.post('/auth/login', data);

const getToken = async (data) => api.post('/auth/get-token', data);

const getUser = async (data) => api.get('/auth/get-user', data); 

const checkPosition = async (data) => api.post('/auth/check-position', data); 

const updateUser = async (data) => api.post('/auth/update-user', data);

const getProducts = async (categorySlug) => api.get(`/products?categorySlug=${categorySlug}`)

const getCategories = async () => api.get(`/categories`)

const updateCart = async (cartItem) => api.put('/cart', {cartItem});

const getCart = async (populate) => api.get(populate ? '/cart?populate=yes' : `/cart`)

const deleteCart = async () => api.del('/cart');

const createOrder = async (data) => api.post('/order', data);

const updateOrder = async (data) => api.put('/order', data);

const getOrders = async (onlyActives) => api.get(onlyActives ? '/order?active=yes' : '/order');

const createAddress = async (data) => api.post('/address', data);

const getAddresses = async () => api.get('/address');

const updateAddress = async (data) =>  api.put('/address', data);

const removeAddress = async (data) =>  api.del('/address', data);

const getOrder = async (_id) => api.get(`/order/${_id}`)

const getTickets = async () => api.get('/tickets');

const createTicket = async (data) => api.post('/tickets', data);

const sendMessage = async (data) => api.post('/tickets/send-message', data);

const calculateDeliveryCost = async (data) => api.post('/order/courier-cost', data);

const cancelOrder = async (data) => api.put("/order/cancelOrder", data);

const deleteUser = async () => api.del("/auth/deleteUser");

export {
    getTickets,
    createTicket,
    sendMessage,
    searchEntity,
    getUser,
    checkPosition,
    login,
    getToken,
    updateUser,
    getProducts,
    getCategories,
    updateCart,
    getCart,
    deleteCart,
    createOrder,
    getOrders,
    createAddress,
    getAddresses,
    updateAddress,
    removeAddress,
    getOrder,
    updateOrder,
    calculateDeliveryCost,
    cancelOrder,
    deleteUser
}