import { IonButton, IonCard, IonIcon } from "@ionic/react";
import { add, remove } from "ionicons/icons";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../App";
import useApi from "../../../hooks/useApi";
import useToast from "../../../hooks/useToast";
import { updateCart } from "../../../services/getir";
import './styles.css';

const GetirCartTool = ({ product, isHorizontal }) => {
    const { user, cart, setCart } = useContext(AppContext);
    const [isInCart, setInCart] = useState(false);
    const [qty, setQty] = useState(0);
    const [present] = useToast();
    const [api] = useApi();
    const [isRunning, setRunning] = useState(false);

    useEffect(() => {
        const cartItem = cart.find(cItem => cItem._product._id === product._id);
        if (cartItem) {
            setInCart(true);
            setQty(cartItem.qty)
        }
        return () => {
            setInCart(false)
            setQty(0)
        }
    }, [cart])

    const addToCart = async () => {
        if (!user?._id) return present({message: 'Səbətə məhsul əlavə etmək üçün qeydiyyat zəruridir.'})
        setRunning(true)
        try {
            const newCartItem = {
                _product: {
                    _id: product._id,
                    shortName: product.shortName,
                    price: product.price,
                    struckPrice: product.struckPrice,
                    shortDescription: product.shortDescription,
                    picUrls: product.picUrls
                },
                qty: qty + 1,
                _user: user._id,
                action: "add",
            }
            const res = await api(updateCart, newCartItem)
            if (res) {
                if (isInCart) {
                    setCart(cart.map(cItem => {
                        if (cItem._product._id === newCartItem._product._id) cItem.qty = newCartItem.qty
                        return cItem;
                    }))
                } else {
                    setCart(prevCart => [...prevCart, newCartItem])
                }
            }
        } catch (error) {
            present('Xəta baş verdi. Təkrar yoxlayın.')
        }
        setRunning(false)
    }

    const removeFromCart = async () => {
        setRunning(true)
        try {
            const newQty = qty - 1;
            const res = await api(updateCart, {
                _product: {
                    _id: product._id
                },
                qty: newQty,
                _user: user._id,
                action: "remove",
            });
            if (!res) throw new Error();
            if (newQty === 0) {
                setCart(cart.filter(cItem => cItem._product._id !== product._id))
            } else {
                setCart(cart.map(cItem => {
                    if (cItem._product._id === product._id) cItem.qty -= 1
                    return cItem;
                }))
            }

        } catch (error) {
            present('Xəta baş verdi. Təkrar yoxlayın.')
        }
        setRunning(false)
    }

    return <div id="GetirCartTool" style={{ transform: isHorizontal ? 'rotate(90deg)' : '', marginTop:isHorizontal ? 45 :0 }}>
        <IonCard disabled={isRunning} onClick={addToCart} className="card increase"
            style={{
                borderBottomLeftRadius: (isInCart ? 0 : 8),
                borderBottomRightRadius: (isInCart ? 0 : 8),
            }}>
            <IonIcon src={add} color="primary" />
        </IonCard>
        {isInCart && <><IonCard className="card count" style={{ transform: isHorizontal ? 'rotate(270deg)' : '' }}>
            <span>{qty}</span>
        </IonCard>
        <IonCard disabled={isRunning} onClick={removeFromCart} className="card decrease" style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
            <IonIcon style={{ transform: isHorizontal ? 'rotate(90deg)' : '' }} src={remove} color="primary" />
        </IonCard></>}
        
    </div>
}

export default GetirCartTool;