import { IonCard, IonCardContent, IonIcon, IonItem, IonLabel } from "@ionic/react";
import { alertCircleOutline, checkmarkCircleOutline, informationCircle, informationOutline, warningOutline } from "ionicons/icons";
import './styles.css';
import courierIcon from '../../assets/images/courier-fin.svg';

const getIcon = (kind) => {
    switch (kind) {
        case 'error':
            return <IonIcon icon={alertCircleOutline} slot="start" color="danger" style={{ fontSize: 36 }} />
        case 'success':
            return <IonIcon icon={checkmarkCircleOutline} slot="start" color="success" style={{ fontSize: 36 }} />
        default:
            return <IonIcon icon={courierIcon} slot="start" color="primary" style={{ fontSize: 36 }} />
    }
}

export default function Alert({ kind, children, ...rest }) {
    return <div id="Alert">
        <IonCard color="light" {...rest} style={{ borderRadius: 10, border: 'unset', ...rest.style }}>
        <IonItem lines="none" color="none" >
            {getIcon(kind)}
            <IonLabel className="ion-text-wrap">
                <span style={{fontWeight: 500}}>
                {children}
                </span>
            </IonLabel>
        </IonItem>
    </IonCard>
    </div>
}