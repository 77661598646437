import { IonLabel, IonTextarea } from "@ionic/react";
import './styles.css'

export default function TextArea({label, inputProps, ...rest}) {

    return <div id="Textarea" {...rest}>
        <IonLabel>
            {label}
        </IonLabel>
        <IonTextarea {...inputProps}/>
    </div>
}