import { IonButton, IonCard, IonContent, IonFooter, IonIcon, IonItem, IonLabel, IonPage, IonText, IonThumbnail } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../App";
import { Alert, CartButton, Header, Image, ProfilePhoto } from "../../components";
import useApi from "../../hooks/useApi";
import useQuery from "../../hooks/useQuery";
import sanitizeHtml from 'sanitize-html';
import { dateTime, price } from "../../utils/format";
import './styles.css';


export default function CampaignDetail({campaign}) {

    return campaign ? <IonContent id="CampaignDetail">
        <IonCard className="listing-container ion-padding">
            <Image src={campaign.photoUrl} />
            <h4>{campaign.title}</h4>
            <a href={campaign.url} target="_blank">Kampaniya linkinə get</a>
        </IonCard>

        <div className="subheading">Detallar</div>
        {(() => {
            const clean = sanitizeHtml(campaign.content);
            return <IonCard className="listing-container ion-padding">
                <div dangerouslySetInnerHTML={{ __html: clean }}></div>
            </IonCard>
        })()}
    </IonContent> : 
    <IonContent>
        <Alert>
            Heç bir kampaniya tapılmadı.
        </Alert>
    </IonContent>
}
