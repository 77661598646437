import { IonText } from "@ionic/react";
import { useContext } from "react";
import { AppContext } from "../../App";
import { getServiceNameByKey } from "../../utils/helpers";

const Logo = ({title, color}) => {
    const {chosenService} = useContext(AppContext);
    const serviceName =  title || getServiceNameByKey(chosenService);
    return color
     ? <IonText color={color}>
         <span className="logo">{serviceName.replace(' ', '')}</span>
     </IonText>
     :<IonText>
        {serviceName.split(' ').map(l => l === 'tezgel' 
        ? <span className="logo" key={l} style={{color: 'var(--ion-color-secondary)'}}>{l}</span> 
        : <span className="logo" key={l} style={{color: 'white'}}>{l}</span>)}
    </IonText>
}

export default Logo;