import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { Autoplay, Keyboard, Pagination, Scrollbar, Zoom } from 'swiper';
import './styles.css';

const sliderTypes = [
    'media',
    'location', // extra
    'vote', // extra
    'image',
]


export default function Slider({slides, hasPagination}) {
    return <Swiper
    spaceBetween={0}
    modules={[Pagination]}
    slidesPerView={1}
    pagination={hasPagination ? ({ clickable: false }) : false}
    onSlideChange={() => {}}
    onSwiper={(swiper) => {}}
  >
      {
          slides.map((s, i) => <SwiperSlide key={i}>
              {s}
          </SwiperSlide>)
      }

  </Swiper>

}