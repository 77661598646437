import { Preferences } from '@capacitor/preferences';

const setValue = async (key, value) => {
  await Preferences.set({
    key,
    value
  });
};

const getValue = async (key) => {
  const value = await Preferences.get({key});
  return value?.value
};

const removeValue = async (key) => {
  return await Preferences.remove({key});
};

export {
  setValue,
  getValue,
  removeValue
}