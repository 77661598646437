const services = [
    {
        id: 1,
        key: 'getir',
        name: 'tezgel'
    },
    {
        id: 2,
        key: 'getirfood',
        name: 'tezgel yemak'
    },
    {
        id: 3,
        key: 'getirtaxi',
        name: 'tezgel taksi'
    },
]

export default services;