import { IonFooter, IonToolbar, IonButton, IonCard, IonCol, IonRow, IonText } from "@ionic/react";
import { useContext } from "react";
import { AppContext } from "../../App";
import { price } from "../../utils/format";
import './styles.css'

export default function CheckoutButton({ onClick , label, cost}) {
    return <IonFooter id="CheckoutButton" className="ion-no-border">
        <IonToolbar className=" ion-padding-bottom">
            <IonCard className="ion-margin">
                <IonRow>
                    <IonCol size={8} className="ion-no-padding">
                        <IonButton onClick={onClick} expand="full" className="submit-button">
                            {label || 'Davam et'}
                        </IonButton>
                    </IonCol>
                    <IonCol size={4} className="ion-no-padding">
                        <IonButton onClick={onClick} color="white" expand="full" className="total-cart">
                            <IonText color="primary">{price(cost)}</IonText>
                        </IonButton>
                    </IonCol>
                </IonRow>
            </IonCard>
        </IonToolbar>
    </IonFooter>
}