// const API_URL = 'http://localhost:3002/v1'
const API_URL = 'https://app-api.tezgel.az/v1'
// const API_URL = 'http://localhost:3002/v1'
const SOCKET_URL = 'https://admin-api.tezgel.az'
const ONESIGNAL_ID = '5ce4651f-23e3-48e7-a69a-a75b3e8d0f35'
const ESRI_API_KEY = 'AAPKcff6093d2a624882a8b81c2c449aad56n1fvwmCb-J9ivwZeMkLCKXt-BeX8ueE_5yMwqUYBLEbyFp_jLhiUQqR3E5xUNpIx'
const ESRI_BASEMAP_ENUM = "ArcGIS:Navigation";
const APP_VERSION = '1.0.3';


export {
    API_URL,
    SOCKET_URL,
    ONESIGNAL_ID,
    ESRI_API_KEY,
    ESRI_BASEMAP_ENUM,
    APP_VERSION
}