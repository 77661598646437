import useAlert from "./useAlert"
import sanitizeHtml from 'sanitize-html';
import useToast from "./useToast";
import { useContext, useState } from "react";
import { AppContext } from "../App";


export default function useApi() {
    const [alrt] = useAlert();
    const [toast] = useToast();
    const [isLoading, setLoading] = useState(false)

    const newMethod = async (method, data) => {
        setLoading(true)
        try {
            const res = await method(data)
            if (res?.popup) {
                const clean = sanitizeHtml(`
                <div class="ion-text-center">
<!--                    <ion-icon src="/assets/bag-icon.svg"/>-->
                </div> 
                <br/>
                ${res.popup.message}
                `, 
                {
                    allowedTags: ['div', 'br', 'ion-icon', 'img'],
                    allowedClasses: {
                        'div': ['ion-text-center']
                    },
                    allowedAttributes: {
                        'img': ["src"],
                        'ion-icon': ['src']
                    },
                });
                alrt({ message: clean, header: res?.popup.header, href: res?.popup.href })
            }
            if (res?.msg) {
                toast({message: res.msg, kind: res.status})
            }
            setLoading(false)
            return res;
        } catch (error) {
            
        setLoading(false)
            alrt({message: error.message})
        }
    }

    return [newMethod, isLoading]
}
