import { IonContent, IonIcon, IonItem, IonLabel, IonText } from "@ionic/react";
import './styles.css';
import Modal from "../Modal";
import courierIcon from '../../assets/images/courier-fin.svg';
import { price } from "../../utils/format";
import { useContext } from "react";
import { AppContext } from "../../App";


export default function DeliveryCostModal(props) {
    const { settings } = useContext(AppContext);
    const deliveryCostSettings = settings.find(s => s.key === 'ORDER_COURIER_COST_SETTINGS')?.value.value;

    return <Modal
        id="DeliveryCostModal"
        breakpoints={[0.6, 1]}
        initialBreakpoint={0.6}
        title={'Çatdırılma Məbləği'}
        {...props}
    >
        <IonContent>
            <div className="ion-text-center ion-margin-vertical">
                <IonIcon src={courierIcon} />
                <h6>Səbət ağırlaşdıqca çatdırılma məbləği azalır.</h6>
            </div>
            <div className="ion-margin">
                {deliveryCostSettings?.map((d, i) => <IonItem
                    key={d.limit}
                    lines="full"
                >
                    <IonLabel>{(() => {
                        switch (i) {
                            case 0:
                                return `${price(d.limit)}'a qədər`
                            case 1:
                                return `${price(deliveryCostSettings[0].limit)} - ${price(d.limit)}`
                            default:
                                return `${price(d.limit)} üzəri`
                        } 
                    })()}</IonLabel>
                    <IonLabel className="ion-text-end">
                        <IonText color="primary">
                            {d.cost === 0 ? 'Pulsuz' : price(d.cost)}
                        </IonText>
                    </IonLabel>
                </IonItem>)}
            </div>
        </IonContent>
    </Modal>
}