import { services } from "../data"
import deliveryCostSettingsSample from "../data/delivery-costs-sample";

const getServiceNameByKey = (key) => {
    const name = services?.find(s => s.key === key).name;
    
    return name || 'getir';
}

const showPopup = (message) => {
    const alert = document.getElementById('my-alert')
    alert['is-open'] = true;
    // return alert.present();
} 

const sleep = ms => new Promise(r => setTimeout(r, ms));

function findCenter(markers) {
    let lat = 0;
    let lng = 0;
    
    for(let i = 0; i < markers.length; ++i) {
        lat += markers[i].lat;
        lng += markers[i].lng;
    }

    lat /= markers.length;
    lng /= markers.length;

    return [lng, lat]
}

const distance = (coor1, coor2) => {
   const x = coor2.x - coor1.x;
   const y = coor2.y - coor1.y;
   return Math.sqrt((x*x) + (y*y));
};
const sortByDistance = (coordinates, point) => {
   const sorter = (a, b) => distance(a, point) - distance(b, point);
   coordinates.sort(sorter);
};

export const findDistanceByLocation = (fromCoors,toCoors, unit="K") => {
    const radlat1 = Math.PI * fromCoors[0]/180
    const radlat2 = Math.PI * toCoors[0]/180
    let theta = fromCoors[1]-toCoors[1]
    let radtheta = Math.PI * theta/180
    let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
        dist = 1;
    }
    dist = Math.acos(dist)
    dist = dist * 180/Math.PI
    dist = dist * 60 * 1.1515
    if (unit=="K") { dist = dist * 1.609344 }
    if (unit=="N") { dist = dist * 0.8684 }
    return dist
}


export {
    getServiceNameByKey,
    showPopup,
    sleep,
    findCenter,
    sortByDistance
}