import { IonButton, IonButtons, IonCard, IonCardContent, IonCheckbox, IonCol, IonContent, IonFooter, IonHeader, IonIcon, IonItem, IonLabel, IonModal, IonPage, IonRow, IonTextarea, IonThumbnail, IonTitle, IonToolbar, useIonViewDidLeave, useIonViewWillEnter } from "@ionic/react";
import { Header, Image, LeafletMap, OrderState, ProfilePhoto } from "../../components";
import './styles.css';
import { useContext, useEffect, useRef, useState } from "react";
import { Map, Placemark } from 'react-yandex-maps';
import orderStates from "../../data/orderStates";
import {basket, call, car, card, cash, chevronForward, create, logoWhatsapp, close} from "ionicons/icons";
import { AppContext } from "../../App";
import courierIcon from '../../assets/images/courier-pin.svg';
import houseIcon from '../../assets/images/house-pin.svg';
import usePosition from "../../hooks/usePosition";
import { getOrder } from "../../services/getir";
import useQuery from "../../hooks/useQuery";
import useApi from "../../hooks/useApi";
import { useHistory } from "react-router";
import useToggleTab from "../../hooks/useToggleTab";
import {findCenter} from "../../utils/helpers";
import io from "socket.io-client";
import { SOCKET_URL } from "../../constants";
import { getValue } from "../../utils/storage";
import L from 'leaflet'
import { Keyboard } from '@capacitor/keyboard';
import { isPlatform } from "@ionic/core";
import { cancelOrder } from "../../services/getir"

import { CallNumber } from '@awesome-cordova-plugins/call-number';
import useToast from "../../hooks/useToast";
import EditOrderNotes from "../../components/EditOrderNotes";
import {useInterval} from "../../hooks/useInterval";
import { price } from "../../utils/format";

const ApproveCancellationModal = ({ isOpen, setOpen, orderID, rest }) => {
    const [message, setMessage] = useState(false);
    const [cancelMessage, setCancelMessage] = useState("Sifarişiniz ləğv olundu");
    const [errorMessage, setErrorMessage] = useState("");

    const submitCancelHandler = async () => {
        const cancelledOrder = await cancelOrder({orderID});
        if (cancelledOrder.updatedOrder) {
            setMessage(true);
            setTimeout(() => {
                setOpen(false)
            }, 1500);
        } else {
            setCancelMessage("Ləğv etmə müddəti bitib");
        }
        console.log(cancelledOrder);
    }

    return (
        <IonModal
        isOpen={isOpen}
        onDidDismiss={() => setOpen(false)}
        {...rest}
    >   
        {
            !message ? (
                <IonHeader className="ion-no-border">
                    <IonToolbar color="primary">
                        <IonButtons slot="secondary">
                            <IonButton slot="primary" onClick={() => setOpen(false)}>
                                <IonIcon src={close} slot="icon-only" />
                            </IonButton>
                        </IonButtons>
                        <IonTitle>Sifarişin ləğvi</IonTitle>
                    </IonToolbar>
                    <IonContent style={{ padding: "15px" }}>
                        Sifarişi ləğv etmək istədiyinizdən əminsiniz?
                    </IonContent>
                    <IonFooter>
                        <IonButton onClick={() => setOpen(false)}>
                            Xeyir
                        </IonButton>
                        <IonButton onClick={submitCancelHandler}>
                            Bəli
                        </IonButton>
                    </IonFooter>
                </IonHeader>
            ) : (
                <IonHeader className="ion-no-border">
                    <IonToolbar color="primary">
                        <IonButtons slot="secondary">
                            <IonButton slot="primary" onClick={() => setOpen(false)}>
                                <IonIcon src={close} slot="icon-only" />
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                    <IonTitle>Sifarişin ləğvi</IonTitle>
                    <IonContent style={{ padding: "15px" }}>
                        {cancelMessage}
                    </IonContent>
                </IonHeader>
            )
        }
    </IonModal>
    )
}

export default function Track() {
    const [order, setOrder] = useState(null);
    const { user, setLoading } = useContext(AppContext);
    const [center, setCenter] = useState(null);
    const [map, setMap] = useState(null);
    const [position] = usePosition()
    const [tok, setTok] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [edit, setEdit] = useState(false);
    const query = useQuery();
    const mapRef = useRef()
    const [toast] = useToast();
    const [api] = useApi();
    const history = useHistory();
    const [markers, setMarkers] = useState([]);
    const [styles, setStyles] = useState({
        'paddingBottom': 0
    })
    const socketRef = useRef();
    const trackCourier = data => {
        // console.log(data)
        // const { _user, position } = JSON.parse(data);
        // if (_user === order?._courier._user) setOrder(prev => ({ ...prev, _courier: { location: { coordinates: position } } }))
    }

    useEffect(() => {
        (async () => {
            const token = await getValue('@token');
            if (token) {
                setTok(token)
            }
        })()
    }, []);


    useEffect(() => {
        if (isPlatform('capacitor')) {
            Keyboard.addListener('keyboardWillShow', info => {
                setStyles({ paddingBottom: info.keyboardHeight })
            });
            Keyboard.addListener('keyboardWillHide', () => {
                setStyles({ paddingBottom: 0 })
            });
        }
    }, [])

    const orderId = query.get('orderId');

    useToggleTab()

    const fetchOrder = async () => {
        const { order } = await api(getOrder, orderId);
        console.log(order)
        setOrder(order);
    }

    useEffect(() => {
        (async () => {
            setLoading(true)
            try {
                if (user?._id) {
                    await fetchOrder()
                }
            } catch (error) {
                console.log(error.message)
                history.push('/home')
            }
            setLoading(false)
        })()
    }, [user])

    useInterval(fetchOrder, 5000);

    function callCourier() {
        if (!order?._courier) return;
        CallNumber.callNumber(order._courier.phoneNumber, true)
            .then(res => console.log('Launched dialer!', res))
            .catch(err => toast({ message: 'Xəta baş verdi.' }));

    }

    useIonViewWillEnter(() => {
        socketRef.current = io(SOCKET_URL, {
            auth: tok
        });
        socketRef.current.on('courier-position', trackCourier)
    })

    useIonViewDidLeave(() => {
        socketRef.current?.disconnect();
    })

    useEffect(() => {
        if (order && map) {
            markers.map(m => map.removeLayer(m))
            var courierIconn = L.icon({
                iconUrl: courierIcon,
                iconSize: [60, 60],
                iconAnchor: [30, 57],
            });
            var houseIconn = L.icon({
                iconUrl: houseIcon,
                iconSize: [60, 60],
                iconAnchor: [30, 57],
            });
            
            if (order._courier) {
               const courierMark = L.marker(order._courier.location.coordinates, { icon: courierIconn, zIndexOffset: 10001 }).addTo(map);
                setMarkers(prev => [...prev, courierMark]);
            }
            const orderMark = L.marker(order.address.location.coordinates, { icon: houseIconn, zIndexOffset: 10000 }).addTo(map);
            setMarkers(prev => [...prev, orderMark]);
        }
    }, [order, map])

    return <IonPage id="Track">
        <Header title={'Sifarişi İzlə'} showAddressBar backButton />
        <IonContent>
            {order && <>
                <LeafletMap setMap={setMap} position={order.address.location.coordinates} />
                <div className="toolbar-container" style={styles}>
                    <IonCard>
                        <IonRow className="ion-justify-content-between ion-text-center">
                            {orderStates.filter(o => o !== 'hot').map(o => <IonCol
                                className="ion-no-padding"
                                size={2} key={o}>
                                <div className={order.state === o ? "point active" : "point"}></div>
                                <IonLabel className={order.state === o ? "state active" : "state"}><OrderState order={{ state: o }} /></IonLabel>
                                {o !== 'delivered' && <div className="line"></div>}
                            </IonCol>)}
                        </IonRow>

                        <IonRow className="ion-justify-content-between ion-text-center profile-card">
                            <IonCol>
                                <IonIcon src={basket} />{price(order.payment.cartCost)}
                            </IonCol>
                            <IonCol>
                                <IonIcon src={order.payment.method === 'cash' ? cash : card} /> Cəmi:{price(order.payment.totalCost)}
                            </IonCol>
                            <IonCol>
                                    <IonIcon src={car} />{price(order.payment.deliveryCost)}
                            </IonCol>
                        </IonRow>


                        {
                            edit ? (
                                <EditOrderNotes order={order} setOrder={setOrder} setEdit={setEdit} />
                            ) : (
                                <IonCard className="profile-card">
                                    <IonItem lines="none" className="ion-no-padding">
                                        <IonThumbnail>
                                            <ProfilePhoto />
                                        </IonThumbnail>
                                        <IonLabel>{order?._courier?.name || 'Kuryer gözlənilir'}</IonLabel>
                                        <IonButton style={{ marginRight: 15 }} onClick={callCourier}>
                                            <IonIcon src={call} />
                                        </IonButton>
                                        <IonButton style={{ marginRight: 15 }} onClick={() => setEdit(true)}>
                                            <IonIcon src={create} />
                                        </IonButton>
                                        <IonButton color="danger" onClick={() => setIsOpen(true)}>
                                            <IonIcon src={close}/>
                                        </IonButton>
                                    </IonItem>
                                </IonCard>
                            )
                        }

                    </IonCard>
                </div></>}
        </IonContent>
        <ApproveCancellationModal isOpen={isOpen} setOpen={setIsOpen} orderID={orderId} />
    </IonPage>
}
