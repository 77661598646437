import { IonCard, IonCardContent, IonContent, IonIcon, IonPage } from '@ionic/react';
import { warning } from 'ionicons/icons';
import { useContext } from 'react';
import { AppContext } from '../../App';
import { Header } from '../../components';
import { GetirHome } from '../../components/Getir';
import { GetirFoodHome, GetirTaxiHome } from '../../components/Homes';

const NotAvailable = () => {

    return <>
        <IonCard style={{ margin: 30 }}>
            <IonCardContent className='ion-text-center'>
                <IonIcon color='primary' src={warning} style={{fontSize: 50}}/>
                <br/>
                Hal-hazırda olduğunuz regionda fəaliyyət göstərilmir.<br/><br/>

                <small>Tezliklə, fəaliyyətə başlayacağıq.</small>
            </IonCardContent>
        </IonCard>
    </>
}

const Home = () => {

    const { chosenService, status } = useContext(AppContext);

    const getHomePageByChosenServiceKey = () => {
        switch (chosenService) {
            case 'getir':
                return <GetirHome />
            case 'getirfood':
                return <GetirFoodHome />
            case 'getirtaxi':
                return <GetirTaxiHome />
            default:
                return <GetirHome />
        }
    }

    return (
        <IonPage>
            <Header showAddressBar powerOffButton />
            <IonContent>
            {getHomePageByChosenServiceKey()}
            </IonContent>
        </IonPage>
    );
};

export default Home;
