import { IonButton, IonCard, IonCheckbox, IonCol, IonItem, IonLabel, IonRow, IonTextarea } from '@ionic/react'
import React, { useState } from 'react'
import useApi from '../../hooks/useApi'
import { updateOrder } from '../../services/getir'
import "./style.css"


const EditOrderNotes = ({ order, setOrder, setEdit }) => {
    const [formData, setFormData] = useState(order)
    const [api, isLoading] = useApi()

    //try <-> catch yazanda onchangelərə ehtiyac qalmayacaq

    const handleSubmit = async () => {
        try {
            await api(updateOrder, formData)
            setOrder({...order, ...formData})
        } catch (error) {
            console.log(error.message)
        }
        setEdit(false)
    }

    return (
        <IonRow id="EditOrderNotes">
            <IonCol size={12}>
                <IonCard>
                <IonTextarea placeholder="Qeyd əlavə edin . . ." class="add-note" value={formData.note} onIonChange={(e) => setFormData({ ...formData, note: e.target.value })}></IonTextarea>
                    <IonRow class=' ion-justify-content-center ion-flex'>
                        <IonCol size={6} className="ion-no-padding">
                            <IonItem lines="none" className="checkout-button border-end">
                                <IonCheckbox checked={formData.leftDoor} onIonChange={(e) => setFormData({ ...formData, leftDoor: e.target.checked })} />
                                <IonLabel>Qapıya Buraxın</IonLabel>
                            </IonItem>
                        </IonCol>
                        <IonCol size={6} className="ion-no-padding">
                            <IonItem className="checkout-button" lines="none" >
                                <IonCheckbox checked={formData.dontRing} onIonChange={(e) => setFormData({ ...formData, dontRing: e.target.checked })} />
                                <IonLabel>Zəngə Basma</IonLabel>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                </IonCard >
            </IonCol>
            <IonCol size={12} className="ion-row-custom ion-padding ion-margin-bottom">
                <IonRow>
                    <IonCol size={6} className="ion-no-padding">
                        <IonButton class="secondary-custom" onClick={() => setEdit(false)}>
                            Bağla
                        </IonButton>
                    </IonCol>
                    <IonCol size={6} className="ion-no-padding" disabled={isLoading}>
                        <IonButton onClick={() => handleSubmit()} >
                            Saxla
                        </IonButton>
                    </IonCol>
                </IonRow>
            </IonCol >
        </IonRow >
    )

}

export default EditOrderNotes;