import { IonContent, IonPage, IonRow, IonCol, IonButton, IonIcon } from "@ionic/react";
import { Header, Input, SectionTitle } from "..";
import { createUserWithEmailAndPassword, getAuth, updateProfile } from 'firebase/auth';
import './styles.css';
import { logoApple, logoFacebook } from "ionicons/icons";
import googleLogo from '../../assets/images/google_logo.svg'
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import useToast from "../../hooks/useToast";
import Modal from "../Modal";
import { AppContext } from "../../App";
import { updateUser } from "../../services/getir";
import useApi from "../../hooks/useApi";

export default function ProfileSettings({isOpen, setOpen, ...rest}) {
    const {user, setUser} = useContext(AppContext)
    const [displayName, setDisplayName] = useState('') 
    const [present, dismiss] = useToast();
    const [api] = useApi();

    useEffect(() => {
        if (user?.displayName) setDisplayName(user.displayName)
    }, [user])

    async function update() {
        try {
            await api(updateUser, {displayName});
            setUser({...user, displayName})
            present({
                message: 'Profil tənzimləndi.',
                duration: 2000
            })
            setOpen(false);
        } catch (error) {
            present({
                message: 'Xəta baş verdi. Təkrar yoxlayın.',
                duration: 2000
            })
        }
    }

    return <Modal title="Tənzimlə" isOpen={isOpen} setOpen={setOpen} id="ProfileSettings" {...rest}>
        <IonContent className="ion-padding">
            <IonRow>
                <IonCol size={12}>
                    <Input
                        label="Ad Soyad"
                        inputProps={{
                            value: displayName,
                            placeholder: 'Sizin adınız və soyadınız',
                            inputmode: 'text',
                            onIonChange: e => setDisplayName(e.target.value)
                        }}
                    />
                </IonCol>
                <IonCol size={12}>
                    <IonButton className="submit-button" onClick={update} expand="block">
                        Təsdiqlə
                    </IonButton>
                </IonCol>
               
            </IonRow>

        </IonContent>
    </Modal>
}