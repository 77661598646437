import { Switch, Redirect, Route } from 'react-router-dom';
import { IonAlert, IonApp, IonContent, IonFab, IonFabButton, IonIcon, IonLoading, IonModal, IonRouterOutlet, IonTab, IonTabBar, IonTabButton, IonTabs, setupIonicReact, useIonRouter, useIonViewWillEnter } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './App.css'

import 'swiper/css';
import "swiper/css/pagination";
import { home, person, search, gift, grid, thumbsUp } from 'ionicons/icons';
import { createContext, useEffect, useState } from 'react';
import { Cart, Landing } from './components';
import routes from './pages/routes';
import { checkPosition, getAddresses, getCart, getCategories, getOrders, getUser, searchEntity } from './services/getir';
import { YMaps } from 'react-yandex-maps';
import { getAuth, onAuthStateChanged, RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
// import 'react-credit-cards/lib/styles-compiled.css'
import useApi from './hooks/useApi';
import { getValue, removeValue, setValue } from './utils/storage';
import OneSignalInit from './utils/onesignal';
import { useInterval } from './hooks/useInterval';
import { Deeplinks } from '@awesome-cordova-plugins/deeplinks';
import { GetirProducts } from './pages/Getir';
import { Home } from './pages';
import usePosition from './hooks/usePosition';


setupIonicReact({
  mode: 'ios',
  swipeBackEnabled: false
});

const Item = (props) => {
  const Inner = props.inner;
  return <Inner {...props} />
}


export const AppContext = createContext();

const App = () => {
  const [position] = usePosition();
  const [chosenService, setChosenService] = useState('getir');
  const [cart, setCart] = useState([]);
  const [activeOrders, setActiveOrders] = useState([])
  const [addresses, setAddresses] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [courierTariff, setCourierTariff] = useState([]);
  const [settings, setSettings] = useState([]);
  const [defaultAddress, setDefaultAddress] = useState(null);
  const [user, setUser] = useState(null)
  const [categories, setCategories] = useState([]);
  const [isLandingOpen, setIsLandingOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isInitializing, setInitializing] = useState(false);
  const [cartTotal, setCartTotal] = useState(0);
  const [campaigns, setCampaigns] = useState([]);
  const [isTabsOpen, setTabsOpen] = useState(true);
  const [status, setStatus] = useState(false);

  

  useEffect(() => {
    Deeplinks.route({
      'products/:categorySlug': GetirProducts,
      '/home': Home
    }).subscribe(match => {
      // if (match.$link.host === '/hots') window.location = match.$link.host
      // else {
      //   fetchOrderByIdAndSetToModal(match.$args.orderId)
      //   setOpenOrderModal(true)
      // }
    }, nomatch => {
      try {
        console.log(nomatch);
        if (nomatch.$link.host === "products") {
          setTimeout(() =>  window.location = nomatch.$link.url.replace('tezgel://', '/'))
        }
      } catch (error) {
        
      }
      // nomatch.$link - the full link data
      console.error('Got a deeplink that didn\'t match', nomatch);
    });

  }, [])


  

  const logOut = () => {
    removeValue('@token')
    setUser(null)
  }

  const checkPosition1 = async () => {
    try {
      
      const {status} = await checkPosition({position})
      console.log(status)
    } catch (error) {
      
    }
  }

  useEffect(() => {
    if (position) checkPosition1()
  }, [position])

  const handleOpeningService = () => {
    // return;
    setChosenService('getir');
    setIsLandingOpen(true);
  }

  useEffect(() => {
    const defAddress = addresses.find(a => a.isDefault);
    setDefaultAddress(defAddress)
  }, [addresses])

  const fetchUser = async () => {
    try {
      const { user } = await getUser()
      setUser(user)
      OneSignalInit(user._id);
    } catch (error) {

    }
  }

  const fetchCart = async () => {
    try {
      const { cart } = await getCart()
      setCart(cart)
    } catch (error) {
      setCart([]);
    }
  }

  const fetchSettings = async () => {
    try {
      const { data } = await searchEntity({
        entity: 'setting',
        limit: 100
      })
      setSettings(data)
    } catch (error) {
      setCart([]);
    }
  }

  const fetchWarehouses = async () => {
    try {
      const { data } = await searchEntity({
        entity: 'warehouse',
      })
      setWarehouses(data)
    } catch (error) {
    }
  }

  const fetchCourierTariff = async () => {
    try {
      const { data } = await searchEntity({
        entity: 'courierTariff',
      })
      setCourierTariff(data)
    } catch (error) {
    }
  }

  const fetchCampaigns = async () => {
    try {
      const { data } = await searchEntity({
        entity: 'campaign',
        filter: {
          limit: 5
        }
      })
      setCampaigns(data)
    } catch (error) {
      setCart([]);
    }
  }

  

  // const ionRouter = useIonRouter();
  
  const fetchActiveOrders = async () => {
    try {
      const { orders } = await getOrders(true)
      setActiveOrders(orders)
    } catch (error) {
      setActiveOrders([]);
    }
  }

  useInterval(fetchActiveOrders, 2000);

  const fetchAddresses = async () => {
    try {
      const { addresses } = await getAddresses()
      setAddresses(addresses)
    } catch (error) {
      setAddresses([]);
    }
  }


  useEffect(() => {
    let newTotal = 0;
    cart.forEach(cartItem => {
      newTotal += cartItem._product.price * cartItem.qty
    })
    setCartTotal(newTotal);
  }, [cart])

  useEffect(() => {
    if (user) {
      fetchCart();
      fetchActiveOrders();
      fetchAddresses();
      fetchWarehouses()
      fetchCourierTariff()
    } else {
      setCart([])
      setActiveOrders([])
      setAddresses([])
    }
  }, [user])

  useEffect(() => {
    // StatusBar.backgroundColorByHexString('#5842B5');
    const token = getValue('@token');
    if (token) fetchUser()
    fetchSettings()
    fetchCampaigns()
  }, []);

  useEffect(() => {
    (async () => {
      if (chosenService === 'getir') {
        setLoading(true)
        try {
          const { categories: data, status } = await getCategories()
          setCategories(data)
        } catch (error) {
          console.log(error);
        }
        setLoading(false)
      }
    })()
  }, [chosenService])



  return (
    <IonApp>

      <YMaps query={{ lang: "tr_TR" }} >
        <AppContext.Provider value={{
          chosenService,
          setChosenService,
          cart,
          setCart,
          categories,
          setCategories,
          isLoading,
          setLoading,
          setInitializing,
          user,
          cartTotal,
          activeOrders,
          setActiveOrders,
          setTabsOpen,
          setAddresses,
          addresses,
          defaultAddress,
          warehouses,
          courierTariff,
          setUser,
          logOut,
          settings,
          campaigns,
          status
        }}>
          <IonReactRouter>
            <IonTabs>
              <IonRouterOutlet animated={false}>
                <Switch>
                  {routes.map(r => {
                    return <Route
                      key={r.name}
                      path={r.path}
                      render={(props) => <Item
                        {...props}
                        inner={r.component}
                      />}
                    />
                  })}
                  <Route exact path="/">
                    <Redirect to="/home" />
                  </Route>
                </Switch>
              </IonRouterOutlet>
              <IonTabBar slot="bottom" className={"btn-color"}
                style={{ display: isTabsOpen ? 'flex' : 'none' }}
              >
                <IonTabButton className={"btn-app"} tab="home" href="/home">
                  <IonIcon icon={home} />
                </IonTabButton>
                <IonTabButton className={"btn-app search-btn"} tab="search" href="/search">
                  <IonIcon icon={search} />
                </IonTabButton>
                <IonTabButton className={"btn-app profile-btn"} tab="profile" href="/profile">
                  <IonIcon icon={person} />
                </IonTabButton>
                <IonTabButton className={"btn-app"} tab="campaigns" href="/campaigns">
                  <IonIcon icon={gift} />
                </IonTabButton>
              </IonTabBar>
            </IonTabs>
            <Landing isOpen={isLandingOpen} setOpen={setIsLandingOpen} />
            <IonFab style={{ display: isTabsOpen ? 'flex' : 'none' }} vertical="bottom" horizontal="center" slot="fixed">
              <IonFabButton
                // onClick={handleOpeningService}
                className={"fab-btn"}>
                <IonIcon className={"fab-icon"} icon={grid}></IonIcon>
              </IonFabButton>
            </IonFab>
          </IonReactRouter>
          <IonLoading
            id="with-bg"
            spinner={null}
            cssClass="load"
            message={'<img src="/assets/tezgel_circle_logo.png"/>'}
            isOpen={isInitializing}
          />
          <IonLoading
            id="without-bg"
            spinner={null}
            cssClass="load"
            message={'<img src="/assets/tezgel_circle_logo.png"/>'}
            isOpen={isLoading}
          />
        </AppContext.Provider>
      </YMaps>
    </IonApp>
  );
}

export default App;
