import { Home, Profile, Search, Campaings, Cart } from ".";
import AddAddress from "./AddAddress";
import Checkout from "./Checkout";
import { GetirProducts } from "./Getir";
import Auth from "./Auth";
import MyAddresses from "./MyAddresses";
import OrderDetail from "./OrderDetail";
import Orders from "./Orders";
import Track from "./Track";
import News from "./News";
import Tickets from "./Tickets";
import ProductDetailPage from "./ProductDetailPage";
import Contact from "./Contact";
import FAQ from "./FAQ";
import AboutUs from "./AboutUs";
import Terms from "./Terms";
import NewDetailPage from "./NewDetailPage";
import CampaignDetailPage from "./CampaignDetailPage";
import DeleteUser from "./DeleteUser";

export default [
    {
        name: 'home',
        path: '/home',
        component: Home
    },
    {
        name: 'auth',
        path: '/auth',
        component: Auth
    },
    {
        name: 'contact',
        path: '/contact',
        component: Contact
    },
    {
        name: 'checkout',
        path: '/checkout',
        component: Checkout
    },
    {
        name: 'search',
        path: '/search',
        component: Search
    },
    {
        name: 'profile',
        path: '/profile',
        component: Profile
    },
    {
        name: 'campaigns',
        path: '/campaigns',
        component: Campaings
    },
    {
        name: 'product-detail',
        path: '/product-detail/:productSlug',
        component: ProductDetailPage
    },
    {
        name: 'news',
        path: '/news',
        component: News
    },
    {
        name: 'getir-products1',
        path: '/products/:categorySlug',
        component: GetirProducts
    },
    {
        name: 'cart',
        path: '/cart',
        component: Cart
    },
    {
        name: 'track',
        path: '/track',
        component: Track
    },
    {
        name: 'add-address',
        path: '/add-address',
        component: AddAddress
    },
    {
        name: 'addresses',
        path: '/addresses',
        component: MyAddresses
    },
    {
        name: 'orders',
        path: '/orders',
        component: Orders
    },
    {
        name: 'orders-detail',
        path: '/order-detail',
        component: OrderDetail
    },
    {
        name: 'faq',
        path: '/faq',
        component: FAQ
    },
    {
        name: 'about-us',
        path: '/about-us',
        component: AboutUs
    },
    {
        name: 'terms',
        path: '/terms',
        component: Terms
    },
    {
        name: 'new-detail',
        path: '/new-detail/:_id',
        component: NewDetailPage
    },
    {
        name: 'campaign-detail',
        path: '/campaign-detail/:_id',
        component: CampaignDetailPage
    },
    {
        name: 'tickets',
        path: '/tickets',
        component: Tickets
    },
    {
        name: "delete-user",
        path: "/delete-user",
        component: DeleteUser
    },
]