import {
    IonSplitPane,
    IonMenu,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    IonCard,
    IonRow,
    IonCol,
    IonMenuButton,
    IonItem,
    IonLabel,
    IonIcon,
    IonThumbnail,
    IonBackButton,
    IonButtons,
    IonInput,
    IonButton,
    IonFooter
} from '@ionic/react'
import { Input, Modal, ProfilePhoto, TextArea } from '../../components'
import './styles.css'
import profileImage from '../../assets/images/profile_image.jpg';
import useToggleTab from "../../hooks/useToggleTab";
import { sendOutline, attachOutline, addOutline } from "ionicons/icons"
import { useCallback, useEffect, useRef, useState } from 'react';
import { getTickets, createTicket, sendMessage } from '../../services/getir';
import useApi from '../../hooks/useApi';
import { date } from '../../utils/format';

const initialValue = { subject: "", message: "" }

const Tickets = () => {
    const [api] = useApi();
    const labelRef = useRef()

    const [selectedTicket, setSelectedTicket] = useState(null)
    const [isOpenTicketModal, setisOpenTicketModal] = useState(false)
    const [formData, setFormData] = useState(initialValue)

    const [message, setMessage] = useState("")

    useToggleTab()

    const [tickets, setTickets] = useState([])

    const fetchTickets = useCallback(
        async () => {
            try {
                let { tickets: t } = await api(getTickets);
                setTickets(t)
            } catch (error) {
                console.log(error.message)
                setTickets([]);
            }
        },
        [api],
    )



    useEffect(() => {
        fetchTickets()
        return () => {
            setTickets([]);
        }
    }, [])


    const handleSubmit = async () => {
        try {
            const { ticket: newTicket } = await api(createTicket, formData)
            setisOpenTicketModal(false)
            setTickets([newTicket, ...tickets])
            setSelectedTicket(newTicket._id)
            setFormData(initialValue)
        }
        catch (error) {
            console.log(error.message)
        }
    }


    const handleChange = (e) => {
        const { name, value } = e.target

        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        })
        )
    }
    const selectedTicketsDetails = tickets.filter(item => item._id === selectedTicket)
    console.log(selectedTicket)


    // send message
    const handleSenderMessage = useCallback(

        async () => {
            try {
                let { messages } = await api(sendMessage, { newMessage: message, _ticket: selectedTicket });
                setMessage("")
                fetchTickets()
            } catch (error) {
                console.log(error.message)
            }
        }
        , [api, fetchTickets, message, selectedTicket],
    )




    const subjectName = selectedTicketsDetails.map((item) => { return item.subject })

    const messagesEndRef = useRef(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
        messagesEndRef.current?.scrollToBottom()
    }
    useEffect(() => {
        scrollToBottom()
    }, [handleSenderMessage])

    return (


        <IonContent>
            <IonSplitPane contentId="tickets" when="md" >
                {/*--  the side menu  --*/}
                <IonMenu contentId="tickets" id='left-panel'>
                    <IonHeader>
                        <IonToolbar color='primary'>
                            <IonButtons slot="secondary">
                                <IonBackButton defaultHref='/profile' text="Geri" />
                            </IonButtons>

                            <IonTitle slot='start'>Müraciətlərim</IonTitle>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent className='listing-messages' >
                        <IonRow>
                            {
                                tickets.map((t, i) => (
                                    <IonCol size='12' key={t._id} onClick={() => selectedTicket !== t._id ? setSelectedTicket(t._id) : setSelectedTicket(null)}>
                                        <IonCard className={selectedTicket === t._id ? "customer-service active" : "customer-service"}>
                                            <IonThumbnail>
                                                <ProfilePhoto url={profileImage} />
                                            </IonThumbnail>
                                            <IonLabel>
                                                <IonTitle class="name">{t.subject}</IonTitle>
                                                <IonTitle class="last-messages">{t.messages[t.messages.length - 1].text.slice(0, 50)}</IonTitle>
                                                <IonTitle class='date'>{date(t.createdAt)}</IonTitle>
                                            </IonLabel>
                                        </IonCard>
                                    </IonCol>
                                ))
                            }
                        </IonRow>

                    </IonContent>
                </IonMenu>

                {/*-- the main content --*/}
                <IonPage id="tickets" >
                    <IonHeader>
                        <IonToolbar color='primary'>
                            <IonTitle slot='start'>{selectedTicket !== null ? subjectName : ""}</IonTitle>
                            <IonMenuButton slot="start"></IonMenuButton>
                        </IonToolbar>
                    </IonHeader>

                    <IonContent className='message-section'>
                        <IonContent ref={messagesEndRef}>


                            {
                                !selectedTicket &&
                                <IonButton className='new-ticket' color='primary' onClick={() => setisOpenTicketModal(true)} >
                                    <IonIcon icon={addOutline} />
                                    <IonTitle>Yeni Müraciət</IonTitle>
                                </IonButton>
                            }

                            {
                                selectedTicketsDetails.map((item, i) => (
                                    <div key={i}>
                                        <IonRow>
                                            <IonCol size='12' >
                                                <IonCard className='message-date'>{date(item.createdAt)}</IonCard>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow className='user-row' >{
                                            item.messages.map((m, i) => (
                                                <IonCol size='12' key={i}>
                                                    <IonItem>
                                                        <IonCard className='message-ballon'>
                                                            {m.text}
                                                            <span className='message-ballon-date' >
                                                                {date(m.createdAt)}
                                                            </span>
                                                        </IonCard>
                                                    </IonItem>
                                                </IonCol>
                                            ))
                                        }
                                        </IonRow>
                                    </div>
                                ))

                            }














                            {/* <IonRow className='service-row'>
                            <IonCol size='12'>
                            <IonItem>
                            <IonCard className='message-ballon'>
                            Salam.
                            </IonCard>
                            </IonItem>
                            </IonCol>
                            <IonCol size='12'>
                            <IonItem>
                            <IonCard className='message-ballon'>
                            Buyurun.
                            </IonCard>
                            </IonItem>
                            </IonCol>
                            <IonCol size='12'>
                            <IonItem>
                            <IonCard className='message-ballon'>
                            Necə kömək edə bilərəm ?
                            </IonCard>
                            </IonItem>
                            </IonCol>
                        </IonRow> */}

                        </IonContent>


                        {selectedTicket &&
                            <IonCard className='attachment'>
                                <IonButton className='attach-file' onClick={() => labelRef.current.click()}>
                                    <IonLabel>
                                        <IonIcon icon={attachOutline} />
                                    </IonLabel>
                                    <input type='file' id="file-input" ref={labelRef} />
                                </IonButton>

                                <IonInput placeholder='Mesajınızı buraya yazın...' type='text' value={message} onIonChange={(e) => setMessage(e.target.value)} />
                                <IonButton onClick={handleSenderMessage} type="submit">
                                    <IonIcon icon={sendOutline} />
                                </IonButton>
                            </IonCard>
                        }


                    </IonContent>

                </IonPage>
            </IonSplitPane>







            {/* modal for subject of new-ticket */}



            <Modal isOpen={isOpenTicketModal} setOpen={setisOpenTicketModal} id="new-ticket-modal">
                <IonContent className='ion-padding'>
                    <IonRow>
                        <IonCol size={12}>
                            <Input label={'Mövzu | Başlıq'} inputProps={

                                {
                                    placeholder: 'Müraciətin mövzusunu buraya yazın...',
                                    name: 'subject',
                                    onIonChange: handleChange,
                                }

                            } />
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size={12}>
                            <TextArea label={'Mesaj'} inputProps={
                                {
                                    placeholder: 'Mesajınızı daxil edin..',
                                    name: 'message',
                                    onIonChange: handleChange,
                                }


                            } />
                        </IonCol>
                    </IonRow>
                </IonContent>
                <IonFooter>
                    <IonToolbar>
                        <IonButton onClick={handleSubmit} expand="block">
                            Göndər
                        </IonButton>
                    </IonToolbar>
                </IonFooter>
            </Modal>







        </IonContent >
    )
}

export default Tickets;