import { IonButton, IonText, IonRow, IonIcon } from '@ionic/react';
import './styles.css';
import { chevronForwardOutline } from 'ionicons/icons';
import { useContext } from 'react';
import { AppContext } from '../../App';
import { useHistory } from 'react-router';
import houseIcon from '../../assets/images/house.svg';

const DeliveryAddressBar = ({ }) => {
    const history = useHistory();
    const { defaultAddress } = useContext(AppContext);

    return <div className="delivery-address-box">

        <div id="button">
            <IonButton
                onClick={() => history.push('/addresses')}
                color="white"
                className="ion-no-padding"
            >
                <IonText color="dark">
                    {defaultAddress
                    ? <span><span className='address-name'>
                        {defaultAddress.name}, </span>
                        {defaultAddress.directions.slice(0, 30)}
                        {defaultAddress.directions.length > 30 && ' ...'}
                        </span>
                    : <span>Çatdırılma ünvanı seçin</span>}
                </IonText>

                <IonIcon
                    color="primary"
                    slot="end"
                    className="ion-margin-end"
                    icon={chevronForwardOutline}
                />
            </IonButton>
        </div>
        <div id="house-icon">
            <IonIcon
                color="primary"
                slot="end"
                icon={houseIcon}
            />
        </div>
    </div>
}

export default DeliveryAddressBar;