import { IonCard, IonContent, IonFooter } from "@ionic/react";
import { Alert, Image } from "../../components";
import sanitizeHtml from 'sanitize-html';
import { price } from "../../utils/format";
import './styles.css';
import { GetirCartTool } from "../Getir";


export default function ProductDetail({ product }) {
    return product ?
        <IonContent id="ProductDetail">
            <IonCard className="listing-container">
                <Image src={product.picUrls[0]} />
                <div className="ProductDetail__main-product-detail-container">

                    <p className='product-title'>{product.name}</p>
                    <p className='product-price'>
                        {product?.struckPrice && <s>{price(product.struckPrice)}</s>} <span>{price(product.price)}</span>
                    </p>
                    <p className='product-sub-title'>{product.shortDescription}</p>
                </div>
            </IonCard>

            <div className="subheading">Detallar</div>
            {(() => {
                const clean = sanitizeHtml(product.description);
                return <IonCard className="listing-container ion-padding">
                    <div dangerouslySetInnerHTML={{ __html: clean }}></div>
                </IonCard>
            })()}

            <IonFooter>
                <IonCard className={''}>
                    <GetirCartTool product={product} isHorizontal={false} inProductDetail={true} />
                </IonCard>
            </IonFooter>
        </IonContent>

        :
        <IonContent>
            <Alert>
                Heç bir məhsul tapılmadı.
            </Alert>
        </IonContent>

}