const mobile_number = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
const exp_date = [/\d/, /\d/, "/", /\d/, /\d/];
const card_number = [/\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/];
const birthdate = [/\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/];
const cvc = [/\d/, /\d/, /\d/];

export const selectMaskInputValue = (mask) => {
  switch (mask) {
    case "exp_date":
      return exp_date;
    case "card_number":
      return card_number;
    case "mobile_number":
      return mobile_number;
    case "birthdate":
      return birthdate;
    case "cvc":
      return cvc;
    default:
      return "";
  }
};

