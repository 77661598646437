import { IonButton, IonCard, IonCardHeader, IonContent, IonIcon, IonItem, IonLabel, IonPage, IonText, IonThumbnail } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../App";
import { Alert, CartButton, Header, Image, Modal, NewDetail } from "../../components";
import useApi from "../../hooks/useApi";
import useToggleTab from "../../hooks/useToggleTab";
import { getNews, searchEntity } from "../../services/getir";
import { dateTime, price } from "../../utils/format";
import './styles.css';
import houseIcon from '../../assets/images/house.svg';
import { useHistory } from "react-router";

export default function News() {
    const [api] = useApi();
    const history = useHistory();
    const { user, setInitializing } = useContext(AppContext)
    const [news, setNews] = useState([]);
    const [isDetailOpen, setDetailOpen] = useState(false);
    useToggleTab();

    const fetchNews = async () => {
        setInitializing(true)
        try {
            const { data } = await api(searchEntity, ({
                entity: 'new',
                filter: {
                    state: 'publish'
                }
            }));
            setNews(data)
        } catch (error) {
            console.log(error.message)
            setNews([]);
        }
        setInitializing(false)
    }

    useEffect(() => {
        fetchNews()
        return () => setNews([])
    }, [])

    return <IonPage id="News">
        <Header title={'Yeniliklər'} backButton backButtonDefaultHref={'/profile'} />
        <IonContent>
            {news.length > 0 ? news.map(o => (<IonCard style={{ padding: 10 }} key={o._id}>
                <Image style={{ borderRadius: 8 }} src={o.photoUrl} />
                <IonItem onClick={() => setDetailOpen(true)} className="News__button-item" button lines="none">
                    <IonLabel>
                        <strong> <small><IonText color="primary">{dateTime(o.createdAt)}</IonText></small>
                            <p>{o.title}</p></strong>
                    </IonLabel>
                </IonItem>

                <Modal
                    title="Yenilik Detalı"
                    isOpen={isDetailOpen}
                    setOpen={setDetailOpen}
                >
                    <NewDetail new1={o} />
                </Modal>
            </IonCard>)) :
                <Alert>
                    Heç bir yenilik tapılmadı.
                </Alert>}
        </IonContent>
    </IonPage>
}