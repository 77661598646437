import { IonButtons, IonCard, IonCol, IonContent, IonHeader, IonLabel, IonPage, IonRow, IonSegment, IonSegmentButton, IonTitle, IonToolbar } from "@ionic/react";
import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../../App";

import useQuery from "../../../hooks/useQuery";
import { getProducts } from "../../../services/getir";
import './styles.css'
import { HashLink as Link } from 'react-router-hash-link';
import { GetirProduct } from "../../../components/Getir";
import { CartButton } from "../../../components";
import { useParams } from "react-router";

const GetirProducts = ({ match }) => {
    const contentRef = useRef();
    const subCategoriesRef = useRef([]);
    const { categories, isLoading, setLoading } = useContext(AppContext);
    const [category, setCategory] = useState(null);
    const [products, setProducts] = useState([]);
    const [subCategorySlug, setSubCategorySlug] = useState(null);
    // const [categorySlug, setCategorySlug] = useState(query.get('categorySlug'));
    const { categorySlug: cS } = useParams()
    const [categorySlug, setCategorySlug] = useState(cS)

    const scrollToTop = () => {
        contentRef.current && contentRef.current.scrollToTop();
    };

    const scrollToCategoryButton = (_id) => {
        document.getElementById(`category-button-${_id}`).scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center'
        });
    }

    useEffect(() => {
        (async () => {
            if (categorySlug && categories && categories.length > 0) {
                setLoading(true)
                try {
                    scrollToTop();

                    if (categorySlug === 'none') {
                        return setCategorySlug(categories[0].slug)
                    }
                    const { category, products } = await getProducts(categorySlug);
                    setCategory(category);
                    setProducts(products)
                    setSubCategorySlug(category.subCategories[0].slug)
                    scrollToCategoryButton(category._id)
                } catch (error) {
                    console.log(error.message)
                }
                setLoading(false)
            }
        })()
    }, [categorySlug, categories])

    const selectSubCategory = (index, slug) => {
        document.getElementById(`sub-category-button-${index}`).scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center'
        });
        const child = subCategoriesRef.current[index];
        contentRef.current.scrollToPoint(0, child.offsetTop - 2, 0);        
    }

    const onContentScroll = () => {
        for (let i = 0; i < subCategoriesRef.current.length; i++) {
            const child = subCategoriesRef.current[i];
            if (isElementInViewPort(child)) {
                setSubCategorySlug(category?.subCategories[i].slug)
                document.getElementById(`sub-category-button-${i}`).scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'center'
                });
            }
        }
    }

    const isElementInViewPort = (el) => {
        const rect = el.getBoundingClientRect();
        if (!rect) return
        return rect.top < 200 && (rect.height + rect.top) > 0
    }

    return <IonPage id="GetirProducts">
        <IonHeader class="ion-no-border">
            <IonToolbar color="primary">
                <IonTitle>
                    Məhsullar
                </IonTitle>
                <IonButtons slot="end">
                    <CartButton />
                </IonButtons>
            </IonToolbar>


            <IonToolbar className="ion-no-padding" color="primary">
                <IonSegment id="category-segment" value={categorySlug} scrollable color="secondary" mode="md"
                    onIonChange={e => setCategorySlug(e.detail.value)}>
                    {categories.map((c, i) => <IonSegmentButton id={`category-button-${c._id}`} value={c.slug} key={c._id}>
                        <span>{c.name}</span>
                    </IonSegmentButton>)}
                </IonSegment>
            </IonToolbar>

            <IonToolbar className="ion-no-padding" id="sub-category-toolbar">
                <IonSegment id="sub-category-segment" value={subCategorySlug} scrollable color="primary" mode="md"
                    >
                    {category?.subCategories?.map((c, i) => <IonSegmentButton
                        key={i}
                        id={`sub-category-button-${i}`}
                        onClick={() => selectSubCategory(i, c.slug)}
                        className={subCategorySlug === c.slug ? 'active' : ''}
                        value={c.slug}>
                        <span>{c.name}</span>
                    </IonSegmentButton>)}
                </IonSegment>
            </IonToolbar>
        </IonHeader>
        <IonContent ref={contentRef} scrollEvents={true} onIonScroll={onContentScroll}>
            <IonRow id="GetirProductList">
                {category?.subCategories.map((c, i) => <IonCol ref={el => subCategoriesRef.current[i] = el} className="ion-no-padding" id={c.slug} key={c._id} size={12}>
                    <h6 className="sub-category-name">{c.name}</h6>
                    <IonCard className="products-card ion-no-margin">
                        <IonRow className="ion-no-padding">
                            {products.map((p, i) => p._subCategories.includes(c._id) ? <IonCol key={p._id} size={4}>
                                <GetirProduct product={p} />
                            </IonCol> : null)}
                        </IonRow>
                    </IonCard>
                </IonCol>)}
            </IonRow>
        </IonContent>
    </IonPage>
}

export default GetirProducts;