import { IonButton, IonCard, IonCardContent, IonContent, IonFooter, IonIcon, IonItem, IonLabel, IonPage, IonText, IonThumbnail } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../App";
import { Alert, CartButton, Header, Image, ProfilePhoto } from "../../components";
import useApi from "../../hooks/useApi";
import useQuery from "../../hooks/useQuery";
import sanitizeHtml from 'sanitize-html';
import { dateTime, price } from "../../utils/format";


export default function FAQ({ }) {
    const { settings } = useContext(AppContext);
    const content = settings.find(s => s.key === 'FAQ_PAGE_CONTENT')?.value || '';

    return <IonPage>
        <Header title="FAQ" backButton backButtonDefaultHref={'/profile'}/>
        <IonContent>
            <IonCard className="ion-no-margin" style={{ borderRadius: 0 }}>
                <IonCardContent>
                    {(() => {
                        const clean = sanitizeHtml(content);
                        return <div dangerouslySetInnerHTML={{ __html: clean }}></div>
                    })()}
                </IonCardContent>
            </IonCard>
        </IonContent>
    </IonPage>

}