import { IonButton, IonCard, IonContent, IonIcon, IonItem, IonLabel, IonPage, IonText, IonThumbnail } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../App";
import { Alert, CartButton, Header, Image, ProfilePhoto } from "../../components";
import useApi from "../../hooks/useApi";
import useQuery from "../../hooks/useQuery";

import useToggleTab from "../../hooks/useToggleTab";
import { getOrder, getOrders } from "../../services/getir";
import { dateTime, price } from "../../utils/format";
import './styles.css';


export default function OrderDetail({ }) {
    const [api] = useApi();

    const { user, setInitializing } = useContext(AppContext)
    const [order, setOrder] = useState(null);
    const query = useQuery();
    const orderId = query.get('orderId');

    useToggleTab();

    useEffect(() => {
        (async () => {
            setInitializing(true)
            try {
                if (user?._id) {
                    const { order } = await api(getOrder, orderId);
                    setOrder(order)
                }
            } catch (error) {

            }
            setInitializing(false)
        })()
    }, [user])

    return <IonPage id="OrderDetail" >
        <Header title={'Sifariş Detalı'} backButton backButtonDefaultHref={'/orders'} />
        {order ? <IonContent>
            <IonCard className="listing-container">
                <IonItem lines="none">
                    <IonIcon
                        className="cart-icon"
                        src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzQiIGhlaWdodD0iMzQiIHZpZXdCb3g9IjAgMCAzNCAzNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yMy44MDY4IDEyLjcwMDFDMjMuNjY2OCAxMi41NTU1IDIzLjQ2OTMgMTIuNDczOSAyMy4yNjIzIDEyLjQ3MzlIMjMuMjYwOEwxMC43MzYzIDEyLjQ5ODlDMTAuNTI4NSAxMi40OTk2IDEwLjMzMTEgMTIuNTgyIDEwLjE5MTggMTIuNzI3M0MxMC4wNTE4IDEyLjg3MTkgOS45ODQwMyAxMy4wNjU4IDEwLjAwMzIgMTMuMjYxMkwxMC45NDA0IDIyLjQ1NTZDMTAuOTYyNSAyMy42NjQ1IDEyLjA3NTEgMjUuMDAwMiAxMy41NTgzIDI1LjAwMDJIMjAuNDUyQzIxLjg1NzkgMjUuMDAwMiAyMy4wNDc4IDIzLjkyNDQgMjMuMDY5OSAyMi42NDMxTDIzLjk5NjkgMTMuMjMzM0MyNC4wMTYgMTMuMDM3OSAyMy45NDY4IDEyLjg0NDcgMjMuODA2OCAxMi43MDAxWiIgZmlsbD0iIzVEM0VCQyIvPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTE5LjEgMTQuNDk5MVYxMS41MjcxQzE5LjEgMTAuMjg4IDE4LjE3ODEgOS4zMTgwNiAxNyA5LjMxODA2QzE1LjgyMTkgOS4zMTgwNiAxNC45IDEwLjI4OCAxNC45IDExLjUyNzFWMTQuNDk5MUMxNC45IDE0Ljg2MjkgMTQuNTg3MSAxNS4xNTgxIDE0LjIgMTUuMTU4MUMxMy44MTI5IDE1LjE1ODEgMTMuNSAxNC44NjI5IDEzLjUgMTQuNDk5MVYxMS41MjcxQzEzLjUgOS41NDk1OCAxNS4wMzU4IDguMDAwMTggMTcgOC4wMDAxOEMxOC45NjQyIDguMDAwMTggMjAuNSA5LjU0OTU4IDIwLjUgMTEuNTI3MVYxNC40OTkxQzIwLjUgMTQuODYyOSAyMC4xODcxIDE1LjE1ODEgMTkuOCAxNS4xNTgxQzE5LjQxMjkgMTUuMTU4MSAxOS4xIDE0Ljg2MjkgMTkuMSAxNC40OTkxWiIgZmlsbD0iI0ZGRDMwMCIvPgo8L3N2Zz4K" />
                    <IonLabel>
                        <IonText color="primary">
                            <strong><small>{dateTime(order.createdAt)}</small></strong>

                        </IonText>
                        <p><IonText color="dark">{order.address.name}, </IonText> {order.address.directions}</p>
                    </IonLabel>
                </IonItem>
            </IonCard>
            <div className="subheading">Kuryer</div>
            <IonCard className="listing-container">
                <IonItem lines="none">
                    <IonThumbnail slot="start">
                        <ProfilePhoto />
                    </IonThumbnail>
                    <IonLabel>
                        {order._courier?.name || 'Kuryer Tapılmadı'}
                    </IonLabel>
                </IonItem>
            </IonCard>
            {order._product && <><div className="subheading">Səbət</div>
            <IonCard className="listing-container">
                {order.cart.map(c => <IonItem key={c._id} lines="full">
                    <IonThumbnail slot="start">
                        <Image src={c._product.picUrls[0]} />
                    </IonThumbnail>
                    <IonLabel>
                        <h5>
                            <strong>
                                <IonText color="primary">
                                    {price(c._product.price)}
                                </IonText>
                            </strong>
                        </h5>
                        <p><strong>{c._product.name}</strong></p>
                        <small>{c._product.shortDescription}</small>
                    </IonLabel>
                    <IonLabel className="ion-text-end ion-padding-end">
                        <span className="qty-span">{c.qty}</span>
                    </IonLabel>
                </IonItem>)}
            </IonCard></>}
            <div className="subheading">Ödəniş</div>
            <IonCard className="listing-container">
                <IonItem lines="full">
                    <IonLabel>Səbət Toplamı</IonLabel>
                    <IonLabel className="ion-text-end ion-padding-end">
                        <strong>{price(order.payment.cartCost)}</strong>
                    </IonLabel>
                </IonItem>
                <IonItem lines="full">
                    <IonLabel >
                        Çatdırılma Məbləği
                    </IonLabel>
                    <IonLabel className="ion-text-end ion-padding-end">
                        <strong>{price(order.payment.deliveryCost)}</strong>
                    </IonLabel>
                </IonItem>
                <IonItem lines="full">
                    <IonLabel >
                        Toplam Məbləğ
                    </IonLabel>
                    <IonLabel className="ion-text-end ion-padding-end">
                       <strong> {price(order.payment.totalCost)}</strong>
                    </IonLabel>
                </IonItem>
            </IonCard>
        </IonContent>: <IonContent>
            <Alert>
                Heç bir sifariş tapılmadı.
                </Alert></IonContent>}
    </IonPage>
}