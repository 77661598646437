import { IonButton, IonCard, IonCardHeader, IonContent, IonIcon, IonItem, IonLabel, IonPage, IonText, IonThumbnail } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../App";
import { Alert, CampaignDetail, CartButton, Header, Image, Modal } from "../../components";
import useApi from "../../hooks/useApi";
import useToggleTab from "../../hooks/useToggleTab";
import { getCampaigns, searchEntity } from "../../services/getir";
import { dateTime, price } from "../../utils/format";
import './styles.css';
import houseIcon from '../../assets/images/house.svg';
import { useHistory } from "react-router";

export default function Campaigns() {
    const [api] = useApi();
    const history = useHistory();
    const { user, setInitializing } = useContext(AppContext)
    const [campaigns, setCampaigns] = useState([]);
    const [isDetailOpen, setDetailOpen] = useState(false);

    const fetchCampaigns = async () => {
        setInitializing(true)
        try {
            const { data } = await api(searchEntity, ({
                entity: 'campaign',
                filter: {
                    state: 'publish'
                }
            }));
            setCampaigns(data)
        } catch (error) {
            console.log(error.message)
            setCampaigns([]);
        }
        setInitializing(false)
    }
    
    useEffect(() => {
        fetchCampaigns()
        return () => setCampaigns([])
    }, [])

    return <IonPage id="Campaigns">
        <Header title={'Kampaniyalar'} />
        <IonContent>
            {campaigns.length > 0 ? campaigns.map(o => (<IonCard style={{ padding: 10 }} key={o._id}>
                <Image style={{ borderRadius: 8 }} src={o.photoUrl} />
                <IonItem onClick={() => setDetailOpen(true)} className="Campaigns__button-item" button lines="none">
                    <IonLabel>
                        <strong> <small><IonText color="primary">{dateTime(o.createdAt)}</IonText></small>
                            <p>{o.title}</p></strong>
                    </IonLabel>
                </IonItem>
                <Modal
                    title="Kampaniya Detalı"
                    isOpen={isDetailOpen}
                    setOpen={setDetailOpen}
                >
                    <CampaignDetail campaign={o} />
                </Modal>
            </IonCard>)) :
                <Alert>
                    Heç bir kampaniya tapılmadı.
                </Alert>}
        </IonContent>
    </IonPage>
} 