import { IonButton, IonCard, IonCol, IonIcon, IonImg, IonItem, IonLabel, IonRow, IonThumbnail } from "@ionic/react";
import { add } from "ionicons/icons";
import { useState } from "react";
import { useHistory } from "react-router";
import { GetirCartTool } from "..";
import { price } from "../../../utils/format";
import Image from "../../Image";
import Modal from "../../Modal";
import ProductDetail from "../../ProductDetail";
import './styles.css'


const GetirProduct = ({ isVertical, product }) => {
    const [isDetailOpen, setDetailOpen] = useState(false)
    const history = useHistory();
    return isVertical
        ? <div id="GetirProduct">
            <GetirCartTool product={product} />
            <IonCard className='product-card' onClick={() => setDetailOpen(true)}>
                <Image src={product.picUrls[0]} style={{ height: "10vh", width: "100%", objectFit: "contain" }} alt={"photo"} />
            </IonCard>

            <p className='product-price'>
                {product?.struckPrice && <s>{price(product.struckPrice)}</s>} <span>{price(product.price)}</span>
            </p>
            <p className='product-title'>{product.shortName}</p>
            <Modal title={'Məhsul Detalı'} isOpen={isDetailOpen} setOpen={setDetailOpen}>
                <ProductDetail product={product}/>
            </Modal>
        </div>
        : <div id="GetirProduct">
            <IonItem lines="none">
                <IonThumbnail  onClick={() => setDetailOpen(true)}>
                    <Image src={product.picUrls[0]} alt={"photo"} />
                </IonThumbnail>
                <IonLabel className="ion-text-wrap">
                    <p className='product-title'>{product.shortName}</p>
                    <p className='product-price'>
                        {product?.struckPrice && <s>{price(product.struckPrice)}</s>} <span>{price(product.price)}</span>
                    </p>
                </IonLabel>
                <GetirCartTool product={product} isHorizontal/>
            </IonItem>
            
            <Modal title={'Məhsul Detalı'} isOpen={isDetailOpen} setOpen={setDetailOpen}>
                <ProductDetail product={product}/>
            </Modal>
        </div>
}

GetirProduct.defaultProps = {
    isVertical: true
}

export default GetirProduct;