import { IonInput, IonLabel } from "@ionic/react";
import './styles.css'

export default function Input({label, inputProps, ...rest}) {

    return <div id="Input" {...rest}>
        <IonLabel>
            {label}
        </IonLabel>
        <IonInput {...inputProps}/>
    </div>
}