import { useContext, useEffect } from "react";
import { AppContext } from "../App";

export default function useToggleTab() {
  const { setTabsOpen } = useContext(AppContext);

  useEffect(() => {
    setTabsOpen(false)
    return () => setTabsOpen(true)
  }, [])
}
