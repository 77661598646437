import { IonButton, IonCheckbox, IonCol, IonContent, IonFooter, IonGrid, IonIcon, IonInput, IonItem, IonLabel, IonPage, IonRow, IonSegment, IonSegmentButton, IonToolbar } from "@ionic/react";
import { calendarOutline, cardOutline, chevronBackOutline, giftOutline, lockClosedOutline } from "ionicons/icons";
import Cards from 'react-credit-cards';
import './styles.css'
import MaskedInput from "react-text-mask";
import { useEffect, useState } from "react";
import { selectMaskInputValue } from "../../utils/mask";
import Modal from "../Modal";
import CheckoutButton from "../CheckoutButton";


const locale = { valid: 'valid thru' };

export default function CardDetails({ data, isOpen, setOpen, onChange, cost }) {
    const [focus, setFocus] = useState('');

    const handleInputFocus = (e) => {
        setFocus(e.target.name)
    }

    const placeholders = { name: data.full_name }

    return <Modal isOpen={isOpen} setOpen={setOpen} title="Kart detalı" breakpoints={[1]}
        initialBreakpoint={1}>
        <IonContent>
            <div className="ion-margin-top" />
            <Cards
                placeholders={placeholders}
                cvc={data.cvc}
                expiry={data.exp_date}
                focused={focus}
                name={data.full_name}
                number={data.card_number}
                locale={locale}
            />
            <form style={{ width: '290px', margin: '0 auto' }}>
                <div className="ion-margin-top" />
                <div>
                    <MaskedInput
                        mask={selectMaskInputValue('card_number')}
                        placeholder="Kart nömrəsi"
                        name="card_number"
                        className="secondary-input"
                        onChange={onChange}
                        inputMode="numeric"
                        onFocus={handleInputFocus}
                    />
                    <IonIcon
                        icon={cardOutline}
                        color="medium"
                        style={{ position: 'absolute', right: 65, fontSize: 30, marginTop: 5 }}
                    />
                </div>
                <div className="ion-margin-top" />
                <IonRow>
                    <IonCol className="ion-no-padding" style={{ paddingRight: 8 }}>
                        <div>
                            <MaskedInput
                                mask={selectMaskInputValue('exp_date')}
                                placeholder="AA/İİ"
                                name="exp_date"
                                inputMode="numeric"
                                className="secondary-input"
                                onChange={onChange}
                                onFocus={handleInputFocus}
                            />
                            <IonIcon
                                icon={calendarOutline}
                                color="medium"
                                style={{ position: 'absolute', right: 23, fontSize: 30, marginTop: 5 }}
                            />
                        </div>
                    </IonCol>
                    <IonCol className="ion-no-padding" style={{ paddingLeft: 8 }}>
                        <div>
                            <MaskedInput
                                mask={selectMaskInputValue('cvc')}
                                placeholder="CVC"
                                name="cvc"
                                inputMode="numeric"
                                className="secondary-input"
                                onChange={onChange}
                                onFocus={handleInputFocus}
                            />
                            <IonIcon
                                icon={lockClosedOutline}
                                color="medium"
                                style={{ position: 'absolute', right: 15, fontSize: 30, marginTop: 5 }}
                            />
                        </div>
                    </IonCol>
                </IonRow>
            </form>
        </IonContent>


        <CheckoutButton label="Ödəniş et" cost={cost}/>
    </Modal>
}