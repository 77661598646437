import './styles.css'
import {
    IonAccordion,
    IonAccordionGroup,
    IonButton,
    IonCard,
    IonCol,
    IonGrid,
    IonIcon,
    IonImg,
    IonItem,
    IonLabel,
    IonList,
    IonRow,
    IonText,
} from "@ionic/react";
import { Slider } from '../..';
import { useHistory } from 'react-router';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../App';
import Image from '../../Image';
import OrderState from '../../OrderState';
import {price} from "../../../utils/format";


const GetirHome = () => {
    const history = useHistory()
    const { categories, activeOrders, campaigns } = useContext(AppContext);
    

    return <IonRow id="GetirHome" className='ion-margin-bottom'>
        <IonCol size={12} className="ion-no-padding">
            <Slider slides={campaigns
                .map((c, i) =>
                    <IonImg key={c._id} src={c.photoUrl} />)}
            />
        </IonCol>
        {activeOrders.length > 0 && <IonCol size={12} className="ion-no-padding" style={{ padding: 14, paddingBottom: 0 }}>
            <IonCard className="ion-no-margin" style={{borderRadius: 10}}>
                <IonAccordionGroup>
                    <IonAccordion value="colors">
                        <IonItem slot="header" color="primary">
                            <div id="order-count">
                                <div>{activeOrders.length}</div>
                            </div>
                            <IonLabel>Aktiv Sifariş</IonLabel>
                        </IonItem>
                        <IonList slot="content">
                            {activeOrders.map((o, i) => <IonItem key={i} lines="none">
                                <div id="order-count">
                                    <IonIcon src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzQiIGhlaWdodD0iMzQiIHZpZXdCb3g9IjAgMCAzNCAzNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yMy44MDY4IDEyLjcwMDFDMjMuNjY2OCAxMi41NTU1IDIzLjQ2OTMgMTIuNDczOSAyMy4yNjIzIDEyLjQ3MzlIMjMuMjYwOEwxMC43MzYzIDEyLjQ5ODlDMTAuNTI4NSAxMi40OTk2IDEwLjMzMTEgMTIuNTgyIDEwLjE5MTggMTIuNzI3M0MxMC4wNTE4IDEyLjg3MTkgOS45ODQwMyAxMy4wNjU4IDEwLjAwMzIgMTMuMjYxMkwxMC45NDA0IDIyLjQ1NTZDMTAuOTYyNSAyMy42NjQ1IDEyLjA3NTEgMjUuMDAwMiAxMy41NTgzIDI1LjAwMDJIMjAuNDUyQzIxLjg1NzkgMjUuMDAwMiAyMy4wNDc4IDIzLjkyNDQgMjMuMDY5OSAyMi42NDMxTDIzLjk5NjkgMTMuMjMzM0MyNC4wMTYgMTMuMDM3OSAyMy45NDY4IDEyLjg0NDcgMjMuODA2OCAxMi43MDAxWiIgZmlsbD0iIzVEM0VCQyIvPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTE5LjEgMTQuNDk5MVYxMS41MjcxQzE5LjEgMTAuMjg4IDE4LjE3ODEgOS4zMTgwNiAxNyA5LjMxODA2QzE1LjgyMTkgOS4zMTgwNiAxNC45IDEwLjI4OCAxNC45IDExLjUyNzFWMTQuNDk5MUMxNC45IDE0Ljg2MjkgMTQuNTg3MSAxNS4xNTgxIDE0LjIgMTUuMTU4MUMxMy44MTI5IDE1LjE1ODEgMTMuNSAxNC44NjI5IDEzLjUgMTQuNDk5MVYxMS41MjcxQzEzLjUgOS41NDk1OCAxNS4wMzU4IDguMDAwMTggMTcgOC4wMDAxOEMxOC45NjQyIDguMDAwMTggMjAuNSA5LjU0OTU4IDIwLjUgMTEuNTI3MVYxNC40OTkxQzIwLjUgMTQuODYyOSAyMC4xODcxIDE1LjE1ODEgMTkuOCAxNS4xNTgxQzE5LjQxMjkgMTUuMTU4MSAxOS4xIDE0Ljg2MjkgMTkuMSAxNC40OTkxWiIgZmlsbD0iI0ZGRDMwMCIvPgo8L3N2Zz4K" />
                                </div>
                                <IonLabel className='ion-no-margin'>
                                    <IonText color="primary"><OrderState order={o}/> | {price(o.payment.totalCost)}</IonText>
                                    <p><IonText color="medium">
                                    {o.address.name}, {o.address.directions}
                                    </IonText></p>
                                </IonLabel>
                                
                                <IonButton fill="outline" routerLink={`/track?orderId=${o._id}`}>
                                    Sifarişi izlə
                                </IonButton>
                            </IonItem>)}
                        </IonList>
                    </IonAccordion>
                </IonAccordionGroup>
            </IonCard>
        </IonCol>}
        <IonCol size={12} className="ion-paddin-top"/>
        {categories.map((cat) => (
            <IonCol className="ion-no-padding" size={"3"} key={cat._id}>
                <IonCard className='category-card' onClick={() => history.push(`/products/${cat.slug}`)}>
                    <Image src={cat.picUrl} style={{ objectFit: "cover", height: "60%", width: "100%" }} alt={"photo"} />
                </IonCard>
                <p className='category-title'>{cat.name}</p>
            </IonCol>
        ))}
    </IonRow>
}

export default GetirHome;
