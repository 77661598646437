import {IonButton, IonCol, IonContent, IonPage, IonRow} from "@ionic/react";
import {Header, Input, OTPModal, SectionTitle} from "../../components";
import './styles.css';
import {useEffect, useState} from "react";
import useToast from "../../hooks/useToast";
import useApi from "../../hooks/useApi";
import {login, searchEntity} from "../../services/getir";
import ReCAPTCHA from "react-google-recaptcha";


export default function Auth() {
    const [displayName, setDisplayName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneInput, setPhoneInput] = useState('');
    const [expires, setExpires] = useState(0);
    const [isRegister, setRegister] = useState(false);
    const [api] = useApi()
    const [present, dismiss] = useToast();
    const [isOTPOpen, setOTPOpen] = useState(false);
    const [hasReferrer, setHasReferrer] = useState(false);
    const [foundReferrer, setFoundReferrer] = useState("");
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [isVerified, setIsVerified] = useState(false);
    const [captchaToken, setCaptchaToken] = useState("");

    useEffect(() => {
        setPhoneNumber('+994' + phoneInput)
    }, [phoneInput])

    const handleReferrerCode = async (e) => {
        const referCodeValue = e.target.value
        if (referCodeValue.length === 9) {
            const {data} = await api(searchEntity, ({entity: 'referrer', filter: { state: 'active'}, limit: 1000}));
            const _foundReferrer = await data.find(a => a.referCode === referCodeValue)
            if (_foundReferrer) {
                setFoundReferrer(_foundReferrer)
                await present({message: 'Kod uğurla tapıldı'})
                await setIsButtonDisabled(false)
            } else {
                await setIsButtonDisabled(true)
                await present({message: 'Bu kodla heç bir referans tapılmadı'})
            }
            // 3X2K8IU3Q
        }else if (referCodeValue.length === 0) {
            await setIsButtonDisabled(false)
        }else {
            await setIsButtonDisabled(true)
        }
    }
    async function loginUser() {
        try {
            if (phoneInput.length < 9)
                return present({
                    message: 'Mobil nömrə düzgün qeyd edilməyib.',
                    duration: 2000
                });
            if (!isVerified) return present({
                message: "Zəhmət olmasa captcha-nı təsdiqləyin",
                duration: 2000
            });
            const {expires, status, message} = await api(login, {isRegister, phoneNumber, captchaToken});
            if (!status) return present({message, duration: 2000});
            setExpires(expires)
            setOTPOpen(true)
        } catch (error) {
            present({
                message: 'Xəta baş verdi. Təkrar yoxlayın.',
                duration: 2000
            })
        }
    }

    const recaptchaHandler = (captcha) => {
        setCaptchaToken(captcha);
        setIsVerified(true);
    }
    

    return <IonPage id="Login">
        <Header title={isRegister ? 'Qeydiyyat' : 'Daxil ol'} />
        <IonContent className="ion-padding">
            {isRegister && <IonRow>
                <IonCol size={12}>
                <Input
                        label="Ad Soyad"
                        inputProps={{
                            placeholder: 'Sizin adınız və soyadınız',
                            inputmode: 'text',
                            name: 'displayName',
                            onIonChange: e => setDisplayName(e.target.value)
                        }}
                    />
                </IonCol>
            </IonRow>}
            <IonRow>
                <IonCol size={4}>
                    <Input
                        label="Ölkə kodu"
                        inputProps={{
                            value: '+994',
                            readonly: true,

                            style: {
                                textAlign: 'end'
                            }
                        }}
                    />
                </IonCol>
                <IonCol size={8}>
                    <Input
                        label="Mobil nömrə"
                        inputProps={{
                            placeholder: '505005050',
                            inputmode: 'number',
                            maxlength: 9,
                            name: 'phoneInput',
                            onIonChange: e => setPhoneInput(e.target.value)
                        }}
                    />
                </IonCol>
            </IonRow>
            {isRegister && <IonRow>
                <IonCol onClick={()=>setHasReferrer(!hasReferrer)} size={12} className="ion-padding-vertical">
                    <SectionTitle title={`Referans ${hasReferrer ? "vermə" : "ver"}`} />
                </IonCol>
            </IonRow>}
            {(isRegister && hasReferrer) && <IonRow>
                <IonCol size={12}>
                    <Input
                        label="Referans kodu"
                        inputProps={{
                            placeholder: '9 hərfli kodu daxil edin',
                            inputmode: 'text',
                            name: 'referrerCode',
                            maxlength:9,
                            onIonInput:e=> handleReferrerCode(e),
                            onIonChange: e => handleReferrerCode(e)
                        }}
                    />
                </IonCol>
            </IonRow>}
            <IonRow>
                <IonCol size={12}>
                    <IonButton disabled={isRegister && isButtonDisabled} onClick={loginUser} expand="block">
                    {isRegister ? 'Qeydiyyat' : 'Daxil ol'}
                    </IonButton>
                </IonCol>
                <IonCol size={12} style={{ display: "flex", justifyContent: "center" }} >
                    <ReCAPTCHA 
                        sitekey="6Ldi1DwmAAAAAPI-DFlVkS0FnwOYLs47Lj-e-GxH"
                        hl="az"
                        onChange={recaptchaHandler}
                    />
                </IonCol>
                <IonCol size={12} className="ion-padding-vertical">
                    <SectionTitle title={`Hesabınız ${isRegister ? 'var' : 'yoxdur'} ?`} />
                </IonCol>
                <IonCol size={12} class="social-buttons">
                    <IonButton onClick={() => setRegister(!isRegister)} color="primary" fill="outline" expand="block">
                        {!isRegister ? 'Qeydiyyat' : 'Daxil ol'}
                    </IonButton>
                </IonCol>
                {/* <IonCol size={4} class="social-buttons">
                    <IonButton onClick={signIn} fill="outline" color="tertiary" expand="block">
                        <IonIcon src={googleLogo} />
                    </IonButton>
                </IonCol>
                <IonCol size={4} class="social-buttons">
                    <IonButton color="tertiary" expand="block">
                        <IonIcon icon={logoFacebook} />
                    </IonButton>
                </IonCol> */}
            </IonRow>

        </IonContent>
        <OTPModal 
        isOpen={isOTPOpen} 
        setOpen={setOTPOpen} 
        expires={expires}
        referrerId={foundReferrer._id}
        displayName={displayName}
        phoneNumber={phoneNumber} 
        isRegister={isRegister}
        />
        

    </IonPage>
}