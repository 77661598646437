import { IonButton, IonButtons, IonCol, IonContent, IonFooter, IonHeader, IonIcon, IonPage, IonRow, IonSearchbar, IonTitle, IonToolbar, useIonViewWillEnter } from "@ionic/react";
import { Alert, Header, Input } from "../../components";
import './styles.css';
import { Map, Placemark, SearchControl } from 'react-yandex-maps';
import { informationCircle, locate } from "ionicons/icons";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import pin from '../../assets/images/pin.svg';
import usePosition from "../../hooks/usePosition";
import useToast from "../../hooks/useToast";
import useApi from "../../hooks/useApi";
import { createAddress } from "../../services/getir";
import useToggleTab from "../../hooks/useToggleTab";
import { useHistory } from "react-router";
import { AppContext } from "../../App";
import LeafletMap from "../../components/LeafletMap";
import { Keyboard } from '@capacitor/keyboard';
import L from 'leaflet'
import { isPlatform } from "@ionic/core";
import * as EsriGeocoder from "esri-leaflet-geocoder"
import { ESRI_API_KEY } from "../../constants";


const AddAddressForm = ({ markerPosition, initialDirections }) => {
    const [api] = useApi();
    const { setAddresses, setLoading } = useContext(AppContext);
    const history = useHistory();
    const [toast] = useToast();
    const [name, setName] = useState('');
    const [directions, setDirections] = useState(initialDirections);


    useEffect(() => {
        if (initialDirections) setDirections(initialDirections)
    }, [initialDirections])


    const [toolbarStyles, setToolbarStyles] = useState({
        'marginBottom': 0
    })

    const onSubmit = async () => {
        // if (name.length > 6) return toast({message: 'Başlıq maks. 8 xanalı olmalıdır.'})
        if (name === '' || directions === '')
            return toast({ message: 'Bütün xanalar doldurulmalıdır.', kind: 'error' })
        setLoading(true)
        try {
            const { address } = await api(createAddress, { coordinates: markerPosition, name, directions })
            setAddresses(prev => ([...prev.map(p => {
                if (p.isDefault) p.isDefault = false;
                return p;
            }), address]))
            history.push('/addresses')
        } catch (error) {
            console.log(error.message)
        }
        setLoading(false)
    }

    useEffect(() => {
        if (isPlatform('capacitor')) {
            Keyboard.addListener('keyboardWillShow', info => {
                setToolbarStyles({ marginBottom: info.keyboardHeight })
            });
            Keyboard.addListener('keyboardWillHide', () => {
                setToolbarStyles({ marginBottom: 0 })
            });
        }
    }, [])

    return <IonToolbar className="AddAddress" style={toolbarStyles}>
        <IonRow>
            <IonCol size={12}>
                <Input
                    label="Başlıq"
                    inputProps={{
                        placeholder: 'Misal üçün: Ev, İş',
                        onIonChange: e => setName(e.target.value)
                    }}
                />
            </IonCol>
            <IonCol size={12}>
                <Input
                    label="Ünvan"
                    inputProps={{
                        placeholder: 'Misal üçün: C. Naxçıvanski 8A',
                        value: directions,
                        onIonChange: e => setDirections(e.target.value)
                    }}
                />
            </IonCol>
            <IonCol size={12}>
                <IonButton className="ion-no-margin" expand="block" onClick={onSubmit}>
                    Təsdiqlə
                </IonButton>
            </IonCol>
        </IonRow>
    </IonToolbar>
}

export default function AddAddress() {
    const [isMarkerDragged, setMarkerDragged] = useState(false);
    const mapRef = useRef();
    const [directions, setDirections] = useState('')
    const [map, setMap] = useState(null);
    const [position] = usePosition();
    const [marker, setMarker] = useState(null)
    const [markerPosition, setMarkerPosition] = useState(null);

    useEffect(() => {
        console.log(markerPosition)
    }, [markerPosition])

    useToggleTab()

    useEffect(() => {
        return () => {
            setMarkerPosition([])
            setMarkerDragged(false)
        }
    }, [])

    useEffect(() => {
        if (position !== [] && !isMarkerDragged && !markerPosition) setMarkerPosition(position);
    })

    useEffect(() => {
        if (map) {
            var searchControl = EsriGeocoder.geosearch({
                // position: "topright",
                placeholder: "Ünvan və ya yer axtarın.",
                useMapBounds: false,
                providers: [
                    EsriGeocoder.arcgisOnlineProvider({
                        // API Key to be passed to the ArcGIS Online Geocoding Service
                        apikey: ESRI_API_KEY
                    })
                ]
            }).addTo(map);

            var draggerIcon = L.icon({
                iconUrl: pin,
                iconSize: [60, 60],
                iconAnchor: [30, 57],
            });

            const mark = L.marker(position, { icon: draggerIcon, draggable: 'true', zIndexOffset: 10000,
            autoPanOnFocus: true, riseOnHover: true }).addTo(map);
            setMarker(mark)

            EsriGeocoder.reverseGeocode({
                apikey: ESRI_API_KEY
            })
                .latlng(position).run(function (error, result, response) {
                    if (result.address) {
                        setDirections(result.address.LongLabel)
                    }
                });
            mark.on('dragend', function (event) {
                let marker = event.target;
                let pos = marker.getLatLng();
                setMarkerDragged(true) // can be the solution
                setMarkerPosition([pos.lat, pos.lng])
                console.log(pos.lat, pos.lng)
                marker.setLatLng(new L.LatLng(pos.lat, pos.lng), { draggable: 'true' });
                EsriGeocoder.reverseGeocode({
                    apikey: ESRI_API_KEY
                })
                    .latlng([pos.lat, pos.lng]).run(function (error, result, response) {
                        if (result.address) setDirections(result.address.LongLabel)
                    });
                map.panTo(new L.LatLng(pos.lat, pos.lng))
            });

            map.addLayer(mark);

            const results = L.layerGroup().addTo(map);

            searchControl.on("results", (data) => {
                results.clearLayers();
                for (let i = data.results.length - 1; i >= 0; i--) {
                    setDirections(data.results[i].properties.LongLabel)
                    const {lat, lng} = data.results[i].latlng;
                    setMarkerPosition([lat,lng])
                    mark.setLatLng(new L.LatLng(lat, lng), { draggable: 'true' });
                    // mark.bindPopup(`<p>${data.results[i].properties.LongLabel}</p>`);
                    results.addLayer(mark);
                    // mark.openPopup();
                }
            });

        }
    }, [map])

    const reLocate = () => {
        setMarkerPosition(position);
        console.log(position);
        const newMarkerPosition = new L.LatLng(position[0], position[1])
        map.panTo(newMarkerPosition)
        EsriGeocoder.reverseGeocode({
            apikey: ESRI_API_KEY
        })
            .latlng(position).run(function (error, result, response) {

                if (result.address) {
                    setDirections(result.address.LongLabel)
                    
                }
            });
        marker.setLatLng(newMarkerPosition, { draggable: 'true' });
    }

    return <IonPage className="AddAddress">
        <Header title="Ünvan Artır" hideCart backButton />
        <IonContent>
            <div id="locate-button" onClick={reLocate} color="light">
                <IonIcon src={locate} color="primary" />
            </div>
            <div id="help">
                <IonIcon color="primary" src={informationCircle} />
                <span>Ünvanı xəritədə seçin</span>
            </div>
            {markerPosition && <LeafletMap setMap={setMap} position={position} />}
           

        </IonContent>
        <IonFooter className="ion-no-border">
            <AddAddressForm initialDirections={directions} markerPosition={markerPosition} />
        </IonFooter>
    </IonPage>
}