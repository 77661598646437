import { IonContent, IonPage, IonRow, IonCol, IonButton, IonIcon, useIonViewWillEnter, useIonToast, IonCard, IonCardContent, IonItem, IonAvatar, IonLabel, IonThumbnail } from "@ionic/react";
import { useContext, useState } from "react";
import { AppContext } from "../../App";
import { Header, Image, ProfilePhoto, ProfileSettings } from "../../components";
import './styles.css';
import profileImage from '../../assets/images/profile_image.jpg';
import { call, callOutline, create, map, logOut as logOutIcon, person, bag, card, heart, helpCircle, help, helpOutline, helpBuoy, warning, informationCircle, newspaper, mail, document, chatboxEllipses, close } from "ionicons/icons";
import { useHistory } from "react-router";
import { APP_VERSION } from "../../constants";

export default function Profile() {
    const { user, logOut } = useContext(AppContext);
    const [isSettingsOpen, setSettingsOpen] = useState(false);
    const history = useHistory();

    const buttons = [
        {
            name: 'Ünvanlar',
            onClick: () => history.push('/addresses'),
            icon: map
        },
        {
            name: 'Keçmiş Sifarişlər',
            onClick: () => history.push('/orders'),
            icon: bag
        },
        {
            name: 'Müraciətlərim',
            onClick: () => { history.push('/tickets') },
            icon: chatboxEllipses
        },
        {
            name: 'Kartlar (Tezliklə)',
            onClick: () => { },
            icon: card
        },
        {
            name: 'Favori Məhsullar (Tezliklə)',
            onClick: () => { },
            icon: heart
        },
        {
            name: "Hesabı Sil",
            onClick: () => { history.push("/auth/deleteUser")},
            icon: close
        },
        // {
        //     name: 'Müraciətlər',
        //     onClick: () => { },
        //     icon: mail
        // },
        {
            name: 'Çıxış',
            onClick: logOut,
            icon: logOutIcon
        }
    ]

    const otherButtons = [
        {
            name: 'Yeniliklər',
            onClick: () => history.push('/news'),
            icon: newspaper
        },
        {
            name: 'Əlaqə',
            onClick: () => history.push('/contact'),
            icon: call
        },
        {
            name: 'FAQ',
            onClick: () => history.push('/faq'),
            icon: helpCircle
        },
        {
            name: 'Şərtlər & Qaydalar',
            onClick: () => history.push('/terms'),
            icon: warning
        },
        {
            name: 'Haqqımızda',
            onClick: () => history.push('/about-us'),
            icon: informationCircle
        },
    ]

    return <IonPage id="Profile">
        <Header title={'Profil'} />
        <IonContent>
            <IonCard className={user?._id ? '' : 'login-button-card'}>
                <IonCardContent>
                    {user?._id ?
                        <div className="ion-padding-vertical">
                            <IonItem lines="none">
                                <IonThumbnail style={{marginRight: 15}}>
                                <ProfilePhoto url={user?.profilePhoto}/>
                                </IonThumbnail>
                                <IonLabel className="ion-text-wrap">
                                    <p>{user.displayName}</p>
                                    <p className="phone-number"> {user?.phoneNumber}</p>
                                </IonLabel>
                                <IonButton fill="clear" onClick={() => setSettingsOpen(true)}>
                                    <IonIcon icon={create} />
                                </IonButton>
                            </IonItem>
                        </div>
                        : <IonItem lines="none" button routerLink="/auth">
                            <IonIcon icon={person} color="primary" slot="start" />
                            <IonLabel>
                                Daxil ol
                            </IonLabel>
                        </IonItem>}
                </IonCardContent>
            </IonCard>

            {user?._id && <IonCard className="buttons-card">
                <IonCardContent>
                    {buttons.map((b, i) => <IonItem onClick={b.onClick} lines="none" button key={i}>
                        <IonIcon slot="start" icon={b.icon} color="primary" />
                        <IonLabel>
                            {b.name}
                        </IonLabel>
                    </IonItem>)}
                </IonCardContent>
            </IonCard>}

            <IonCard className="buttons-card">
                <IonCardContent>
                    {otherButtons.map((b, i) => <IonItem onClick={b.onClick} lines="none" button key={i}>
                        <IonIcon slot="start" icon={b.icon} color="primary" />
                        <IonLabel>
                            {b.name}
                        </IonLabel>
                    </IonItem>)}
                </IonCardContent>
            </IonCard>

            <IonCard className="buttons-card">
                <IonCardContent>
                    <IonItem lines="none">
                        <IonLabel>
                            Versiya
                        </IonLabel>
                        <IonLabel className="ion-text-end">
                            <small>{APP_VERSION}</small>
                        </IonLabel>
                    </IonItem>
                </IonCardContent>
            </IonCard>
        </IonContent>

        <ProfileSettings
            isOpen={isSettingsOpen}
            setOpen={setSettingsOpen}
            breakpoints={[0.3, 1]}
            initialBreakpoint={0.3}
        />
    </IonPage>
}
