import {
    IonContent,
    IonPage, IonSearchbar, IonToolbar,

} from "@ionic/react";
import { Header } from "../../components";
import { Virtuoso } from 'react-virtuoso';

import "./styles.css";
import { useEffect, useState } from "react";
import { searchEntity } from "../../services/getir";
import { GetirProduct } from "../../components/Getir";

const Search = () => {
    const [products, setProducts] = useState([]);
    const [searchText, setSearchText] = useState('');


    const clearData = () => {
        setProducts([]);
    }
    
    const searchData = async () => {
        try {
            const {data, countFetched} = await searchEntity({
                entity: 'product',
                limit: 100,
                skip: 0,
                filter: {
                    name: { $regex: searchText.toLowerCase(), $options: 'i' }
                }
            })
            setProducts(data)
        } catch (error) {
            clearData()
        }
    }

    useEffect(() => {
        if (searchText != '') searchData()
        else clearData()
    }, [searchText])
    return (
        <IonPage id="Search">
            <Header className="Search__header ion-no-border">
                <IonToolbar className="Search__header_toolbar" color="primary">
                    <IonSearchbar onIonChange={e => setSearchText(e.detail.value)} placeholder="Axtar" className="Search__header__toolbar__searchbar" />
                </IonToolbar>

            </Header>
            <IonContent className="Search__content"  scrollY={false}>
                <Virtuoso
                     className="ion-content-scroll-host"
                    style={{ height: '100%' }}
                    totalCount={products.length}
                    itemContent={(index) => {
                        return (
                            <div style={{ height: '65px', padding: 15 }}>
                                <GetirProduct product={products[index]} isVertical={false}/>
                            </div>
                        );
                    }}
                />
            </IonContent>
        </IonPage>
    );
};

export default Search;
