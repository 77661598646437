import { IonButton, IonCard, IonContent, IonIcon, IonItem, IonLabel, IonPage, IonText } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../App";
import { Alert, CartButton, Header } from "../../components";
import useApi from "../../hooks/useApi";
import useToggleTab from "../../hooks/useToggleTab";
import { getOrders } from "../../services/getir";
import { dateTime, price } from "../../utils/format";
import './styles.css';
import houseIcon from '../../assets/images/house.svg';
import { useHistory } from "react-router";



export default function Orders() {
    const [api] = useApi();
    const history = useHistory();
    const { user, setInitializing } = useContext(AppContext)
    const [orders, setOrders] = useState([]);
    
    useToggleTab();

    const fetchOrders = async () => {
        setInitializing(true)
        try {
            const { orders: o } = await api(getOrders);
            setOrders(o)
        } catch (error) {
            console.log(error.message)
            setOrders([]);
        }
        setInitializing(false)
    }

    useEffect(() => {
        if (user?._id) fetchOrders()
    }, [user])

    return <IonPage id="Orders" >
        <Header title={'Keçmiş Sifarişlər'} backButton backButtonDefaultHref={'/profile'} />
        <IonContent>
            <IonCard className="listing-container">
                {orders.length > 0 ? orders.map(o => (<IonItem onClick={() => history.push('/order-detail?orderId=' + o._id) } button key={o._id}>
                    <IonIcon src={houseIcon} slot="start" />
                    <IonLabel>
                        <small><IonText color="primary">{dateTime(o.createdAt)}</IonText></small>
                        <p>{o.address.name}</p>
                    </IonLabel>
                    <IonLabel style={{fontSize: 20}} className="ion-text-end ion-padding-end">
                        <IonText color="primary">
                            {price(o.payment.totalCost)}
                        </IonText>
                    </IonLabel>
                </IonItem>)) :
                    <Alert>
                        Heç bir sifariş tapılmadı. Aktiv sifarişlərinizi əsas səhifədən izləyə bilərsiniz.
                    </Alert>}
            </IonCard>
        </IonContent>
    </IonPage>
}