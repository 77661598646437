import {ONESIGNAL_ID} from '../constants';

// Call this function when your app start
function OneSignalInit(userId) {
    console.log(window)
    window["plugins"].OneSignal.setAppId(ONESIGNAL_ID);
    window["plugins"].OneSignal.setExternalUserId(userId);
    console.log(userId, 'onesignal')
    window["plugins"].OneSignal.setNotificationOpenedHandler(function(jsonData) {
        console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
    });

    // iOS - Prompts the user for notification permissions.
    //    * Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 6) to better communicate to your users what notifications they will get.
    window["plugins"].OneSignal.promptForPushNotificationsWithUserResponse(function(accepted) {
        console.log("User accepted notifications: " + accepted);
    });
}

export default OneSignalInit;
