import { IonButton, IonCard, IonContent, IonFooter, IonIcon, IonItem, IonLabel, IonPage, IonText, IonThumbnail } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../App";
import { Alert, CartButton, Header, Image, ProfilePhoto } from "../../components";
import useApi from "../../hooks/useApi";
import useQuery from "../../hooks/useQuery";
import sanitizeHtml from 'sanitize-html';
import { dateTime, price } from "../../utils/format";
import './styles.css';


export default function NewDetail({new1}) {

    return new1 ? <IonContent id="NewDetail">
        <IonCard className="listing-container ion-padding">
            <Image src={new1.photoUrl} />
            <h4>{new1.title}</h4>
        </IonCard>

        <div className="subheading">Detallar</div>
        {(() => {
            const clean = sanitizeHtml(new1.content);
            return <IonCard className="listing-container ion-padding">
                <div dangerouslySetInnerHTML={{ __html: clean }}></div>
            </IonCard>
        })()}
    </IonContent> : 
    <IonContent>
        <Alert>
            Heç bir yenilik tapılmadı.
        </Alert>
    </IonContent>

}