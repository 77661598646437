import { IonButton, IonCard, IonCardContent, IonCol, IonContent, IonFooter, IonModal, IonPage, IonRow, IonText, IonToolbar } from "@ionic/react";
import { trash } from "ionicons/icons";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { AppContext } from "../../App";
import useToast from "../../hooks/useToast";
import { deleteCart, getCart } from "../../services/getir";
import { price } from "../../utils/format";
import Alert from "../../components/Alert";
import CheckoutButton from "../../components/CheckoutButton";
import { GetirProduct } from "../../components/Getir";
import Header from "../../components/Header";
import './styles.css'
import { Logo } from "../../components";
import useToggleTab from "../../hooks/useToggleTab";

export default function Cart() {
    const history = useHistory();
    
    const { cart, setCart, cartTotal, setTabsOpen } = useContext(AppContext);
    const [present, dismiss] = useToast();

    useToggleTab()

    const cleanCart = async () => {
        try {
            const confirmed = window.confirm('Səbət təmizlənəcəkdir. Davam etmək istəyirsiniz ?')
            if (confirmed) {
                await deleteCart();
                setCart([]);
                present({ message: 'Səbət təmizləndi.' })
            }
        } catch (error) {
            present({ message: 'Xəta baş verdi.' })
        }
    }

    return ( <IonPage id="Cart">
        <Header backButton title={'Səbət'} right_buttons={[cart.length > 0 && {
            icon: trash,
            onClick: cleanCart
        }]} />
        {cart.length > 0
            ? <>
                <IonContent>
                    <IonCard className="square-card">
                        <IonCardContent>
                            {cart.map((cItem, i) => <GetirProduct key={i} product={cItem._product} isVertical={false} />)}
                        </IonCardContent>
                    </IonCard>
                </IonContent>
                <CheckoutButton onClick={() => history.push('/checkout')} cost={cartTotal} />
            </>
            : <IonContent>
                <Alert>
                    Səbət boşdur.
                </Alert>
            </IonContent>}
    </IonPage>)
}