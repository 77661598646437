import { IonButtons, IonHeader, IonButton, IonTitle, IonToolbar, IonBackButton, IonIcon } from '@ionic/react';
import { Logo } from '..';
import CartButton from '../CartButton';
import DeliveryAddressBar from '../DeliveryAddressBar';
import { isPlatform } from '@ionic/react';

import './styles.css';
import { power } from 'ionicons/icons';
import { App as App1 } from '@capacitor/app';

const Header = ({ powerOffButton, hideCart, title, left_buttons, right_buttons, showAddressBar, backButton, backButtonDefaultHref, children, ...rest }) => {
    return <IonHeader {...rest}>
        <IonToolbar color="primary">
            {powerOffButton && <IonButtons slot="secondary">
                <IonButton style={{fontWeight: 500}} onClick={App1.exitApp}>
                    <IonIcon icon={power} slot="start" />
                    Çıxış Et
                </IonButton>
            </IonButtons>}
            {backButton && <IonButtons slot="secondary">
                <IonBackButton defaultHref={backButtonDefaultHref} text="Geri" />
            </IonButtons>}
            {left_buttons && left_buttons.map((b, i) => <IonButtons key={i} slot="secondary">
                <IonButton onClick={b.onClick} fill="clear">
                    <IonIcon slot="icon-only" icon={b.icon} />
                </IonButton>
            </IonButtons>)}
            <IonTitle>
                {title || <Logo />}
            </IonTitle>
            {right_buttons ? right_buttons.map((b, i) => <IonButtons key={i} slot="primary">
                <IonButton onClick={b.onClick} fill="clear">
                    <IonIcon slot="icon-only" icon={b.icon} />
                </IonButton>
            </IonButtons>) : <IonButtons slot="end">
                {!hideCart && <CartButton />}
            </IonButtons>}
        </IonToolbar>
        {showAddressBar && <DeliveryAddressBar />}
        {children}
    </IonHeader>
}

export default Header;


