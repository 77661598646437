import { API_URL } from "../constants";
import { getValue } from "./storage";

const HEADERS = { 'Content-Type': 'application/json', 'lang': 'tr', "access-control-allow-origin" : "*" };



export const post = async (url, data) => {
    const TOKEN = await getValue('@token')
    return fetch(`${API_URL}${url}`,
        {
            method: 'POST',
            body: JSON.stringify(data),
            headers: TOKEN ? { ...HEADERS, Authorization: 'Bearer ' + TOKEN } : HEADERS
        })
        .then(handleResponse)
        .catch(handleError)
}

export const put = async (url, data) => {
    const TOKEN = await getValue('@token')
    return fetch(`${API_URL}${url}`,
        {
            method: 'PUT',
            body: JSON.stringify(data),
            headers: TOKEN ? { ...HEADERS, Authorization: 'Bearer ' + TOKEN } : HEADERS
        })
        .then(handleResponse)
        .catch(handleError)
}

export const del = async (url, data) => {
    const TOKEN = await getValue('@token')
    return fetch(`${API_URL}${url}`,
        {
            method: 'DELETE',
            body: JSON.stringify(data),
            headers: TOKEN ? { ...HEADERS, Authorization: 'Bearer ' + TOKEN } : HEADERS
        })
        .then(handleResponse)
        .catch(handleError)
}

export const get = async (url) => {
    const TOKEN = await getValue('@token')
    return fetch(`${API_URL}${url}`,
        {
            method: 'GET',
            headers: TOKEN ? { ...HEADERS, Authorization: 'Bearer ' + TOKEN } : HEADERS
        })
        .then(handleResponse)
        .catch(handleError)
}

export const handleResponse = async response => {
    const data = await response.json();
    if (response.ok) {
        // if (data.msg) toast.success(data.msg);
        return data;
    }
    throw new Error(data?.msg || 'An error occurred.');
}

export const handleError = err => {
    // toast.error(err.message);
    throw err;
}
