import {
    IonContent,
    IonPage,
    IonCard,
    IonCardContent,
    IonItem,
    IonAvatar,
    IonLabel,
    IonThumbnail,
    IonInput,
    IonCol,
    IonRow,
    IonRadio,
    IonCheckbox,
    IonRadioGroup,
    IonIcon,
    IonText,
    IonButton,
    useIonAlert
} from "@ionic/react";
import { card, cash, informationCircle } from "ionicons/icons";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { AppContext } from "../../App";
import { Alert, CardDetails, CheckoutButton, DeliveryAddressBar, DeliveryCostModal, Header, Modal } from "../../components";
import deliveryCostSettingsSample from "../../data/delivery-costs-sample";
import useApi from "../../hooks/useApi";
import useToast from "../../hooks/useToast";
import useToggleTab from "../../hooks/useToggleTab";
import { calculateDeliveryCost, createOrder } from "../../services/getir";
import { price } from "../../utils/format";
import './styles.css';
import {findDistanceByLocation} from "../../utils/helpers";

export default function Checkout() {
    const [toast] = useToast()
    const history = useHistory();
    const [isModalOpen, setModalOpen] = useState(false)
    const [threedsUrl, setThreedsUrl] = useState(null);
    const [presentAlert] = useIonAlert();
    const [formData, setFormData] = useState({
        note: '',
        leftDoor: false,
        dontRing: false,
        paymentMethod: 'card',
        card_number: '',
        cvc: '',
        full_name: '',
        exp_date: '',
    });

    useEffect(() => {
        window.addEventListener('window:message', (event) => {
            if (event === 'success') {
                toast({message: 'Sifariş başa çatdı. Əsas səhifədən izləyə bilərsiniz.'});
                history.push('/home')
            } else {
                toast({message: 'Onlayn ödəniş uğursuz oldu'});
            }
            setModalOpen(false);
        });
    }, []);



    const [api] = useApi();
    const [deliveryCost, setDeliveryCost] = useState(0);
    const [isDeliveryCostModalOpen, setDeliveryCostModalOpen] = useState(false);
    const [isCardDetailsOpen, setCardDetailsOpen] = useState(false);
    const { cartTotal, cart, user, setLoading, setActiveOrders, defaultAddress, setCart, warehouses, courierTariff } = useContext(AppContext);
    const [referrer, setReferrer] = useState({});

    useToggleTab()

    useEffect(() => {
        if (threedsUrl) setModalOpen(true)
        else setModalOpen(false)
    }, [threedsUrl])

    useEffect(() => {
        if (isModalOpen === false) setThreedsUrl(null)
    }, [isModalOpen])

    // useEffect(() => {
    //     (async () => {
    //         const calculateDeliveryCost = async () => {
    //             return new Promise(res => {
    //                 deliveryCostSettingsSample.forEach(c => {
    //                     if (cartTotal <= c.limit) res(c.cost)
    //                 })
    //             })
    //         }
    //         const dC = await calculateDeliveryCost();
    //         setDeliveryCost(cartTotal === 0 ? 0 : dC)
    //     })()
    // }, [cartTotal])

    const onChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({ ...prevData, [name]: value }))
    }

    const onHandleReferrerValues = () => {
        let activeReferProductsTotalPrice = 0
        cart.forEach(c=>{
            if(c._product.isReferCodeActive){
                console.log(c._product.price)
                activeReferProductsTotalPrice += c._product.price
            }
        })
        setReferrer({id:user?.referrerId,activeReferProductsTotalPrice})
    }

    const handleDeliverCost = () => {
        let distance = 100
        let warehouseItem = 0
        let selectedWarehouse
        warehouses.forEach(w=>{
            warehouseItem = findDistanceByLocation(w.location.coordinates,defaultAddress.location.coordinates)
            if(warehouseItem < distance){
                distance = warehouseItem
                selectedWarehouse = w
            }
        })
        warehouseItem = warehouseItem.toFixed(1)
        distance = distance.toFixed(1)
        
    }

    async function calculateCourier() {
        try {
            console.log(defaultAddress.location.coordinates)
            const {deliveryCost} = await calculateDeliveryCost({address: defaultAddress, cost: cartTotal});
            setDeliveryCost(deliveryCost)
        } catch (error) {
            setDeliveryCost(0)
        }
    }

    useEffect(()=>{
        onHandleReferrerValues()
        handleDeliverCost()
        
    },[])

    useEffect(() => {
        defaultAddress && calculateCourier()
    }, [defaultAddress])

    const handleWithAlert = (successCallback) => {
        presentAlert({
            header: 'Təsdiqləyirsiniz?',
            buttons: [
                "Xeyr",
                {
                    text: 'Təsdiqlə',
                    handler: successCallback
                }
            ]
        })
    }

    const sendOrder = async () => {
        setLoading(true)
        try {
            if (formData.paymentMethod === 'cash') {
                const { order } = await api(createOrder, { ...formData, address: defaultAddress, referrerValues:referrer, deliveryCost });
                setActiveOrders(prevOrders => [...prevOrders, { ...order, _address: defaultAddress }]);
                setCart([])
            } else {
                const { url } = await api(createOrder, { ...formData, address: defaultAddress, referrerValues:referrer, deliveryCost });
                setThreedsUrl(url)
            }

        } catch (error) {
            console.log(error.message)
        }
        setLoading(false)
    }



    return <IonPage id="Checkout">
        <Header hideCart title={'Ödəniş et'} showAddressBar backButton backButtonDefaultHref='/cart' />
        <IonContent>
            <div className="ion-margin-top" />
            <div className="subheading">Xüsusi Qeydlər</div>
            <IonCard className="checkout-card">
                <IonInput
                    className="note-input"
                    name="note"
                    onIonChange={onChange}
                    placeholder="Bura qeydlərinizi yaza bilərsiniz."
                />
                <IonRow>
                    <IonCol size={6} className="ion-no-padding">
                        <IonItem lines="none" className="checkout-button">
                            <IonCheckbox checked={formData.leftDoor} onIonChange={e => setFormData({ ...formData, leftDoor: e.detail.checked })} />
                            <IonLabel>Sifarişi Qapıya Buraxın</IonLabel>
                        </IonItem>
                    </IonCol>
                    <IonCol size={6} className="ion-no-padding">
                        <IonItem className="checkout-button" lines="none">
                            <IonCheckbox checked={formData.dontRing} onIonChange={e => setFormData({ ...formData, dontRing: e.detail.checked })} />
                            <IonLabel>Zəngə Basma</IonLabel>
                        </IonItem>
                    </IonCol>
                </IonRow>
            </IonCard>
            <div className="ion-margin-top" />
            <div className="subheading">Ödəniş Üsulu</div>
            <IonCard className="checkout-card">
                <IonItem lines="none">
                    <IonCheckbox onClick={e => setFormData({ ...formData, paymentMethod: "card" })}
                        checked={formData.paymentMethod === 'card'} slot="start" value="card" />
                    <IonLabel className="ion-text-wrap">
                        Kart ilə Ödəniş
                        <p><small style={{ fontWeight: 600 }}>Kart detalları bir sonrakı addımda istəniləcək.</small></p>
                    </IonLabel>
                    <IonIcon className="payment-method-icon" src={card} />
                </IonItem>

                <IonItem lines="none">
                    <IonCheckbox onClick={e => setFormData({ ...formData, paymentMethod: "cash" })}
                        checked={formData.paymentMethod === 'cash'} slot="start" value="cash" />
                    <IonLabel className="ion-text-wrap">
                        Qapıda Ödəniş
                        <p><small style={{ fontWeight: 600 }}>Ödəniş təhvil zamanı həyata keçəcək.</small></p>
                    </IonLabel>
                    <IonIcon className="payment-method-icon" src={cash} />
                </IonItem>
            </IonCard>
            <div className="ion-margin-top" />
            <div className="subheading">Ödəniş Detalı</div>
            <IonCard className="checkout-card payment-detail">
                <IonItem>
                    <IonLabel>
                        Səbət Toplamı
                    </IonLabel>
                    <IonLabel className="ion-text-end">
                        {price(cartTotal)}
                    </IonLabel>
                </IonItem>
                <IonItem>
                    <IonLabel>
                        Çatdırılma Məbləği
                    </IonLabel>
                    {/* <IonButton onClick={() => {
                        setDeliveryCostModalOpen(true)
                    }} fill="clear" style={{ marginLeft: '-80px' }}>
                        <IonIcon icon={informationCircle} slot="icon-only" />
                    </IonButton> */}
                    <IonLabel className="ion-text-end">
                        {price(deliveryCost)}
                    </IonLabel>
                </IonItem>
                {/* {deliveryCost > 0 && <Alert className="delivery-cost-alert">
                    <strong>{price(deliveryCostSettingsSample[2].limit - cartTotal)}</strong> sonra <strong>çatdırılma pulsuz!</strong> Bir neçə məhsul daha əlavə edin.
                </Alert>} */}
                <IonItem className="total-item">
                    <IonLabel>
                        Toplam
                    </IonLabel>
                    <IonLabel className="ion-text-end">
                        {price(cartTotal + deliveryCost)}
                    </IonLabel>
                </IonItem>
                <IonItem className="terms-item" lines="none">
                    <IonCheckbox slot="start" checked disabled />
                    <IonLabel className="ion-text-wrap">
                        Sifarişə davam edərək <IonText color="primary">Şərtlər və Qaydalar</IonText> ilə razılaşıram.
                    </IonLabel>
                </IonItem>
            </IonCard>
        </IonContent>
        <CheckoutButton
            label={formData.paymentMethod === "card" ? 'Ödəniş et' : 'Sifariş et'}
            onClick={()=>handleWithAlert(sendOrder)}
            cost={cartTotal + deliveryCost}
        />
        <DeliveryCostModal
            isOpen={isDeliveryCostModalOpen}
            setOpen={setDeliveryCostModalOpen}
        />
        <CardDetails
            isOpen={isCardDetailsOpen}
            setOpen={setCardDetailsOpen}
            data={formData}
            onChange={onChange}
            cost={cartTotal + deliveryCost}
            onSubmit={sendOrder}
        />

        <Modal isOpen={isModalOpen} setOpen={setModalOpen} title={'Onlayn Ödəniş'}>
            <IonContent>
                <iframe src={threedsUrl} style={{ border: 'none', width: '100%', height: '100%' }}></iframe>
            </IonContent>
        </Modal>
    </IonPage>
}
