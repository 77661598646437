import { IonButton, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonModal, IonPage, IonRow, IonSearchbar, IonText, IonToolbar } from "@ionic/react";
import { searchOutline } from "ionicons/icons";
import { useContext, useState } from "react";
import { useHistory } from "react-router";
import { DeliveryAddressBar, Logo, Slider } from "..";
import { AppContext } from "../../App";
import { services } from "../../data";
import Header from "../Header";
import './styles.css';

export default function Landing({ isOpen, setOpen }) {
    const { setChosenService } = useContext(AppContext);
    const history = useHistory();
    const setService = (service) => {
        setChosenService(service.key);
        setOpen(false);
        history.push('/home')
    }
    return <IonModal isOpen={isOpen} id="Landing">
        <Header>
        <IonToolbar className="ion-no-padding">
                <DeliveryAddressBar />
            </IonToolbar>
        </Header>
        <IonContent fullscreen>
            <IonGrid style={{ height: '100%' }} className="ion-no-padding">
                <IonRow>
                    <IonCol>
                        <Slider hasPagination
                            slides={[1, 2, 3, 4]
                                .map((s, i) => <div key={i} style={{ width: '100%', height: '200px', backgroundColor: `#eee` }} />)}
                        />
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonText color="primary">
                        <h4 className="ion-padding-start">Salam!</h4>
                    </IonText>
                </IonRow>
                <IonRow onClick={() => history.push('/search')} id="search-button" className="ion-margin-horizontal">
                    <IonCol size={1}>
                        <IonIcon src={searchOutline} />
                    </IonCol>
                    <IonCol size={11} className="ion-align-self-center ion-padding-start">
                        <span>Nə axtarırsınız?</span>
                    </IonCol>
                </IonRow>
                <IonRow id="gates" class=" ion-align-items-center ion-padding-horizontal">
                    {services.map(s => <IonCol onClick={() => setService(s)} key={s.id} size={12}
                        className="ion-margin-top">
                        <div className="gate-box">
                            <Logo title={s.name} color="primary" />
                        </div>
                    </IonCol>)}

                </IonRow>
            </IonGrid>
        </IonContent>
    </IonModal>
}