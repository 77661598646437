import { IonButton, IonButtons, IonHeader, IonIcon, IonModal, IonTitle, IonToolbar } from '@ionic/react';
import { close } from 'ionicons/icons';
import './styles.css';

export default function Modal({ title, isOpen, setOpen, secondButton, children, ...rest }) {

    return <IonModal
        isOpen={isOpen}
        onDidDismiss={() => setOpen(false)}
        {...rest}
    >
        <IonHeader className="ion-no-border">
            <IonToolbar color="primary">
                <IonButtons slot="secondary">
                    <IonButton slot="primary" onClick={() => setOpen(false)}>
                        <IonIcon src={close} slot="icon-only" />
                    </IonButton>
                </IonButtons>
                <IonTitle>{title}</IonTitle>
                {secondButton && <IonButtons slot="primary">
                    <IonButton slot="primary" onClick={secondButton.onClick}>
                        <IonIcon src={secondButton.icon} slot="icon-only" />
                    </IonButton>
                </IonButtons>}
            </IonToolbar>
        </IonHeader>
        {children}
    </IonModal>
}