import { IonButton, IonCard, IonContent, IonFooter, IonIcon, IonItem, IonLabel, IonPage, IonText, IonThumbnail } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../App";
import { Alert, CartButton, Header, Image, Modal, CampaignDetail, ProfilePhoto } from "../../components";
import useApi from "../../hooks/useApi";
import useQuery from "../../hooks/useQuery";
import sanitizeHtml from 'sanitize-html';
import useToggleTab from "../../hooks/useToggleTab";
import { getCampaign, getCampaigns, searchEntity } from "../../services/getir";
import './styles.css';
import { useParams } from "react-router";

export default function CampaignDetailPage({}) {
    const [api] = useApi();
    
    const { user, setInitializing } = useContext(AppContext)
    const [campaign, setCampaign] = useState(null);
    const {_id} = useParams()
    useToggleTab();

    useEffect(() => {
        (async () => {
            setInitializing(true)
            try {
                const { data } = await api(searchEntity, {
                    entity: 'campaign',
                    filter: { _id, state: 'publish' }
                });
                setCampaign(data[0])
            } catch (error) {

            }
            setInitializing(false)
        })()
        return () => setCampaign(null)
    }, [])

    return <IonPage id="CampaignDetailPage" >
        <Header title={'Yenilik Detalı'} backButton backButtonDefaultHref={'/campaigns'} />
        <CampaignDetail campaign={campaign}/>
    </IonPage>
}

